import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import * as api from '../../api';
import * as types from '../../store/db/types';
import {getRegistrationName} from '../../utils';

interface Props {
  match: types.Match;
  competition: types.Competition;
  onSave(result: number): void;
  onClose(): void;
}

const EditMoveMatch = (props: Props) => {
  const {t} = useTranslation();

  const [availableMatches, setAvailableMatches] = useState<types.Available[]>([]);
  const [available, setAvailable] = useState<number>();
  const [errMsg, setErrMsg] = useState<string>();

  useEffect(() => {
    (async () => {
      if (availableMatches.length === 0) {
        setAvailableMatches(await api.get(`matches/available/${props.competition.id}`));
      }
    })()
  }, [availableMatches.length, props.competition]);

  const onSave = () => {
    if (!available) {
      setErrMsg(t('ERROR_REQUIRED'));
    }
    else {
      props.onSave(available);
    }
  };

  const renderOptions = () => {
    return availableMatches.filter(item => moment(item.date_time).unix() > moment().unix())
        .sort((a, b) => moment(a.date_time).unix() - moment(b.date_time).unix())
        .map(item => <option value={item.id} key={item.id}>
          {moment(item.date_time).format('DD-MM-YYYY HH:mm')}
          &nbsp;- {item.court.toLowerCase().includes('baan') ? item.court : t('COURT') + ' ' + item.court }
        </option>);
  }

  const {match} = props;

  return (
      <div className="popup">
        <div className="header">{t('EDIT_MOVE_MATCH_TITLE')}</div>
        <div className="content">
          <p>{t('EDIT_MOVE_MATCH_TEXT')}</p>
          <p>
            <i>{t('CURRENT_TIME')}</i>: {match.date_time ? moment(match.date_time).format('DD-MM-YYYY HH:mm')
              : t('NOT_PLANNED')} - <i>{t('COURT')}</i>: {match.court ? match.court : '-'}<br/>
            <i>{t('PLAYERS')}</i>: {getRegistrationName(t, match, 'H') + ' - ' + getRegistrationName(t, match, 'A')}
          </p>
          <label style={{color: 'red'}}>{errMsg}</label>
          <select
              value={available}
              placeholder={t('MOVE_MATCH')}
              required
              onChange={(event) => setAvailable(+event.target.value)}
          >
            <option value="0">{t('SELECT_OPTION')}</option>
            {match.date_time ? <option value="-1">{t('MOVE_TO_UNPLANNED')}</option> : null}
            {renderOptions()}
          </select>
          <input
              type="button"
              value={'' + t('SAVE')}
              className="button"
              onClick={onSave}
              disabled={available === undefined || available === 0}
          />
          <input
              type="button" value={'' + t('CANCEL')}
              className="lastButton second"
              onClick={props.onClose}
          />
        </div>
      </div>
  );
}

export default EditMoveMatch;