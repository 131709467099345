import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import * as api from 'api';
import * as utils from 'utils';
import * as types from 'store/db/types';
import * as authTypes from 'store/auth/types';

import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import Email from 'components/Form/Email';
import Name from 'components/Form/Name';
import Gender from "components/Form/Gender";
import PlayerLevel from "components/Form/PlayerLevel";
import MobilePhoneNumber from "components/Form/MobilePhoneNumber";
import PhoneNumber from "components/Form/PhoneNumber";
import PlayerNumber from "components/Form/PlayerNumber";

interface LocationState {
  competition: types.Competition;
  player?: types.Player;
  user: authTypes.User;
}

interface Form {
  name: string;
  gender: string;
  mobile: string;
  phone: string;
  playerNumber: string;
  levelDouble: string;
  levelSingle: string;
  levelPadel: string;
  email: string;
}

const Player = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState
  const competition = locationState.competition;
  const player = locationState.player;
  const user = locationState.user;

  const [username, setUsername] = useState<string>();

  const {handleSubmit, errors, register} = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: player?.name,
      gender: player?.gender,
      mobile: player?.mobile,
      phone: player?.phone,
      levelDouble: player?.level_double || '9',
      levelSingle: player?.level_single || '9',
      levelPadel: player?.level_padel || undefined,
      email: player?.email,
      PlayerNumber: player?.number
    }
  });

  useEffect(() => {
    (async() => {
      if (player) {
        setUsername(await api.get(`auth/username/${player.id}`));
      }
    })();
  }, [player]);
  
  const onSave = async (data: Form) => {
    try {
      const clubs = player?.clubs || [];
      if (!clubs.includes(competition.club_id)) {
        clubs.push(competition.club_id);
      }
      const savePlayer: types.Player = {
        clubs,
        email: data.email,
        gender: data.gender === '1' ? 1 : 2,
        id: player?.id || -1,
        level_double: data.levelDouble,
        level_padel: data.levelPadel,
        level_single: data.levelSingle,
        mobile: data.mobile,
        name: data.name,
        number: data.playerNumber,
        phone: data.phone
      };
      if (player) {
        await api.put(`players/${player.id}`, savePlayer);
      } else {
        await api.post('players', savePlayer);
      }
      toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
      history.goBack();
    } catch (error) {
      toast(t(error.response.data), {type: toast.TYPE.ERROR});
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  if (!utils.isManager(user, competition)) {
    return <p>{t('NO_AUTHORIZATION')}</p>
  }

  return (
      <div>
        <Breadcrumb title={'PLAYER'} />
        <form onSubmit={handleSubmit(onSave)}>
          <p dangerouslySetInnerHTML={{__html: t('EDIT_PLAYER_TEXT')}} />
          {username && <p style={{color: "#666"}}>{t('ACCOUNT_NAME')}: <i>{username}</i></p>}
          <div className="field">
            <Name register={register} errors={errors.name} newLayout/>
          </div>
          <div className="field">
            <Gender register={register} errors={errors.gender} newLayout/>
            <label>{t('GENDER')}</label>
          </div>
          <div className="field">
            <PlayerLevel name="levelSingle" placeholderKey="LEVEL_SINGLE" disablePlaceholder
                          register={register} errors={errors.levelSingle} newLayout/>
            <label>{t('LEVEL_SINGLE')}</label>
          </div>
          <div className="field">
            <PlayerLevel  name="levelDouble" placeholderKey="LEVEL_DOUBLE" disablePlaceholder
                          register={register} errors={errors.levelDouble} newLayout/>
            <label>{t('LEVEL_DOUBLE')}</label>
          </div>
          <div className="field">
            <PlayerLevel  name="levelPadel" placeholderKey="LEVEL_PADEL"
                          register={register} errors={errors.levelDouble} newLayout/>
            <label>{t('LEVEL_PADEL')}</label>
          </div>
          <div className="field">
            <Email register={register} errors={errors.email} newLayout/>
          </div>
          <div className="field">
            <MobilePhoneNumber register={register} errors={errors.mobile} newLayout/>
          </div>
          <div className="field">
            <PhoneNumber register={register} errors={errors.phone} newLayout/>
          </div>
          <div className="field">
            <PlayerNumber register={register} errors={errors.PlayerNumber} newLayout/>
          </div>
          <input type="submit" value={'' + t('SAVE')} />
          <input type="button" className="second" value={'' + t('CANCEL')} onClick={onCancel} />
        </form>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default Player;