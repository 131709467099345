import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  errors: any;
  errorMsgKey: string;
}

const ErrorMessageMinMaxLength = ({errors, errorMsgKey}: Props) => {
  const [t] = useTranslation();

  return (
    <Fragment>
      {errors && (errors.type === "max" || errors.type === "min") && <p className="error">{t(errorMsgKey)}</p>}
    </Fragment>)
}

export default ErrorMessageMinMaxLength;
