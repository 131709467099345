import React, { Fragment, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useForm} from "react-hook-form";

import ReCAPTCHA from 'react-google-recaptcha';

import * as api from '../../api';

import Breadcrumb from 'components/Breadcrumb';
import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';
import ErrorMessage from 'components/Validation/ErrorMessage';

import styles from 'containers/Auth/SignUp.module.scss';

interface Form {
  accountName: string;
  email: string;
  captchaToken: string | null;
}

const PasswordForget = () => {
  const {t} = useTranslation();
  const { register, handleSubmit, getValues, setValue, errors } = useForm<Form>({mode: "onBlur"});

  useEffect(() => {
    register({name: 'captchaToken'}, {required: true});    
  });

  const onSubmit = (data: Form) => {
    if (data.accountName) {
      api.post(`auth/forgot-password`, { name: data.accountName})
        .then(() => {
          toast(t('SEND_SUCCESS'), {type: toast.TYPE.SUCCESS});
          setValue('accountName', '');
          setValue('captchaToken', null);
        })
        .catch(error => {
          toast(api.transformErrorResponse(error, t), {type: toast.TYPE.ERROR});
        });
    } else if (data.email) {
      api.post(`auth/forgot-username`, { email: data.email})
        .then(() => {
          toast(t('SEND_SUCCESS'), {type: toast.TYPE.SUCCESS});
          setValue('email', '');
          setValue('captchaToken', null);
        })
        .catch(error => {
          toast(t(error.response.data), {type: toast.TYPE.ERROR});
        });
    }
  };

  const validateFields = {
    bothValues: (value?: string) => {
      if (value === '' && getValues().accountName === '') {
        return t('VALIDATION_ONE_FIELD_REQUIRED');
      }
      if (value !== '' && getValues().accountName !== '') {
        return t('VALIDATION_ONLY_ONE_FIELD_ALLOWED');
      }
      return true;
    }
  };

  const onVerifyCaptcha = (token: string | null) => {
    setValue('captchaToken', token);
  };

  return (
    <Fragment>
      <Breadcrumb title="PASSWORD_FORGET" />
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <div className={styles.form}>
              <p dangerouslySetInnerHTML={{__html: t('PASSWORD_FORGET_TEXT')}} />
              <input name="accountName" ref={register} type="text" placeholder={t('ACCOUNT_NAME')} />
              <hr />
              <p dangerouslySetInnerHTML={{__html: t('USERNAME_FORGET_TEXT')}} />
              <input name="email" ref={register({validate: validateFields})} type="text" placeholder={t('EMAIL_ADDRESS')} />
              <ErrorMessage errors={errors.email} errorType="bothValues" />
              <ReCAPTCHA sitekey="6LdstbYUAAAAABylvCWs2ReAbHoA3Jt9PsMs4z0j" onChange={onVerifyCaptcha} />
              <ErrorMessageRequired errors={errors.captchaToken} />
              <input type="submit" value={getValues().email ? t('REQUEST_ACCOUNT_NAME')! : t('RESET_PASSWORD')!} />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default PasswordForget;
