import * as types from './types';
import {Dispatch} from 'redux';
import {User} from './types';

const constructAction = (type: string, payload: any) => ({ type, payload });

export const storeUser = (user: User | null) => {
  return (dispatch: Dispatch) => {
    dispatch(constructAction(types.STORE_USER, user));
  }
};