import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Typeahead} from '@gforge/react-typeahead-ts';

import * as types from '../../store/db/types';
import * as api from '../../api';

interface Props {
  competition: types.Competition;
  onClose(): void;
}

const SelectAdministrator = ({competition, onClose}: Props) => {
  const {t} = useTranslation();

  const [administrators, setAdministrators] = useState<types.Player[]>([]);
  const [administrator, setAdministrator] = useState<types.Player | null>(null);

  const getAdministrators = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 3) {
      setAdministrators([]);
      return
    }

    const players: types.Player[] = await api.get(`players/search/${event.target.value}?club=${competition.club_id}`);
    setAdministrators(players);
  }

  const selectAdministrator = (value: any) => {
    const admin = administrators.find(item => item.id === value.id);
    if (admin) {
      setAdministrator(admin);
    }
  }

  const onAdd = async (event: React.FormEvent) => {
    if (!administrator) {
      toast(t('NO_ADMINISTRATOR_SELECTED'), {type: toast.TYPE.WARNING});
      return;
    }

    try {
      await api.post(`competitions/${competition.id}/admin/${administrator.id}`, {});
      toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
    }

    onClose();
  }

  return (
      <div className="popup">
        <div className="header">{t('SELECT_ADMINISTRATOR')}</div>
        <div className="content">
          {t('SELECT_ADMINISTRATOR')}<br />
          <Typeahead
              options={administrators.map(item => ({id: item.id, name: item.name}))}
              filterOption="name"
              displayOption="name"
              inputDisplayOption="id"
              onChange={(event) => getAdministrators(event)}
              onOptionSelected={(value) => selectAdministrator(value)}
          />
          <input
              type="button"
              value={'' + t('ADD')}
              className="button"
              onClick={onAdd}
          />
          <input
              type="button" value={'' + t('CANCEL')}
              className="lastButton second"
              onClick={onClose}
          />
        </div>
      </div>
  );
}

export default SelectAdministrator;