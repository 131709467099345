import * as types from './types';
import {User} from './types';

interface Action {
  type: string,
  payload: any
}

export interface AuthReducer {
  auth: State;
}

interface State {
  user: User | null;
}

const defaultState: State = {
  user: null
};

const reducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case types.STORE_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default reducer;