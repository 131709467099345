import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  title: string;
  message: string;
  onYes(): void;
  onNo(): void;
}

const Confirm = ({title, message, onYes, onNo}: Props) => {
  const {t} = useTranslation();

  return (
      <div className="popup">
        <div className="header">{title}</div>
        <div className="content">
          <p dangerouslySetInnerHTML={{__html: message}} />
          <input
              type="button"
              value={'' + t('YES')}
              className="button"
              onClick={onYes}
          />
          <input
              type="button"
              value={'' + t('NO')}
              className="lastButton second"
              onClick={onNo}
          />
        </div>
      </div>
  );
}

export default Confirm;