import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import * as types from '../../store/db/types';
import * as api from '../../api';
import Loading from 'components/Common/Loading';

import styles from './RegistrationAvailability.module.scss';

interface Props {
  competitionId: number;
  playerId: number;
  onChange(availability: types.Availability): void;
}

const RegistrationAvailability = ({competitionId, playerId, onChange}: Props) =>  {
  const {t} = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [matchDates, setMatchDates] = useState<types.MatchDate[]>([]);
  const [dayAvailability, setDayAvailability] = useState<types.DayAvailability[]>([]);
  const [unavailableDates, setUnavailableDates] = useState<string[]>(['']);
  const [renderNr, setRenderNr] = useState<number>(0);

  useEffect(() => {
    (async () => {

      setMatchDates(await api.get(`matches/${competitionId}/dates`));
      const availability: types.Availability = await api.get(`players/${playerId}/available/${competitionId}`);
      const weekPlanning: types.WeekPlanning[] = await api.get(`matches/${competitionId}/week-planning`);

      const weekAvailability: types.DayAvailability[] = availability.week;
      const dayAvailability: types.DayAvailability[] = weekPlanning.map(wp => {
        const times: types.TimeAvailability[] = wp.times.map(time => {
          const da = weekAvailability.find(wa => wa.weekday === wp.day);
          const available = !da || da.times.find(dayTime => dayTime.time === time) !== undefined;
          return { time, available };
        });
        return {
          weekday: wp.day,
          times
        };
      });

      const unavailableDates = availability.unavailableDates;
      unavailableDates.push('');
      setUnavailableDates(unavailableDates);

      setDayAvailability(dayAvailability);
      onChange({
        week: dayAvailability,
        unavailableDates: availability ? availability.unavailableDates : []
      });
      setLoading(false);
    })();
  }, []);

  const onChangeTimeAvailability = (dayIdx: number, timeIdx: number, checked: boolean) => {
    const availability = dayAvailability;
    if (availability) {
      availability[dayIdx].times[timeIdx].available = checked;
      setDayAvailability(availability);
      setRenderNr(renderNr + 1);
      onChange({week: availability, unavailableDates});
    }
  }

  const onChangeUnavailableDate = (value: string, idx: number) => {
    const dates = unavailableDates;
    dates[idx] = value;
    if (dates.length === idx + 1) {
      dates.push('');
    }
    setUnavailableDates(dates);
    setRenderNr(renderNr + 1);
    onChange({week: dayAvailability, unavailableDates: dates});
  }

  const renderDayAvailability = () => {
    return dayAvailability.map((item, dayIdx) => (
        <tr key={item.weekday}>
          <td>{t(`WEEKDAY_${item.weekday}`)}</td>
          {item.times.map((time, weekIdx) => (
              <td key={item.weekday + time.time} className={styles.check}>
                <input type="checkbox" id={item.weekday + time.time} checked={time.available}
                       onChange={(event) => onChangeTimeAvailability(dayIdx, weekIdx, event.target.checked)} />
                <label htmlFor={item.weekday + time.time}>{time.time}</label>
              </td>
          ))}
        </tr>
    ))
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <div className={styles.weekPlanning}>
        <hr />
        <label>{t('AVAILABILITY_PER_DAY')}</label><br/>
        <table>
          <tbody>
            {renderDayAvailability()}
          </tbody>
        </table>
        <hr />
        <label>{t('DAYS_NOT_AVAILABLE')}</label><br/>
        {unavailableDates.map((ud, idx) => {
          return (
          <select key={idx} value={ud} onChange={(event) => onChangeUnavailableDate(event.target.value, idx)}>
            <option value="" />
            {matchDates.map((date) => {
              const day = moment(date.date);
              return <option key={date.date} value={date.date}>{day.format(t('DATE_FORMAT'))}</option>
            })}
          </select>
        )})}
      </div>
  );
}

export default RegistrationAvailability
