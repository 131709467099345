import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cn from 'classnames';

import * as ROUTES from '../constants/routes';
import { Competition, News } from '../store/db/types';
import * as actions from '../store/db/actions';

import styles from './Home.module.scss';

import SelectCompetition from '../components/Competition/SelectCompetition';
import AdSense from '../components/AdSense';

const Home = () => {

  const {t} = useTranslation();
  const competition: Competition | null = useSelector((state: any) => state.db.competition);
  const commonNews: News[] = useSelector((state: any) => state.db.commonNews);
  const [showSelectPopup, setShowSelectPopup] = useState<boolean>(false);

  useEffect(() => {
    if (commonNews.length === 0) {
      actions.getCommonNews();
    }
  }, [commonNews]);

  const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  const isRegistrationOpen = (): boolean => {
    const registrationFromMoment = moment(competition?.registration_from ? competition.registration_from : 0);
    const registrationUntilMoment = moment(competition?.registration_to ? competition.registration_to : 0);
    const now = moment();

    return registrationFromMoment.isBefore(now) && registrationUntilMoment.isAfter(now);
  }

  const isCompetitionActive = (): boolean => {
    const registrationUntilMoment = moment(competition?.registration_to ? competition.registration_to : 0);
    const now = moment();

    return registrationUntilMoment.isBefore(now);
  }

  const renderRegistered = (registered: string | null) => {
    if (registered === 'true') {
      return (<div className="info">
        Je account is geregistreerd.<br />
        Er is een e-mail gestuurd naar het opgegeven e-mailadres om dit adres te verifieren.
      </div>)
    }
    return null;
  };

  const renderText = () => {
    if (competition?.sport === 'Tennis') {
      return (<div className="info">
        <p><b>Mededeling van de competitie beheerder:</b></p>
        <p>Heb je nog niet eerder aangemeld? Klik vooral niet op registreren. Alle spelers zijn al bekend.
        <br />Klik op <i>Aanmelden</i> en vervolgens op <i>Wachtwoord vergeten</i>
        <br />Geef je gebruikersnaam in om een nieuw wachtwoord te genereren of je e-mail adres als je je gebruikersnaam niet weet.
        </p></div>);
    }
    return null;
  }

  const renderNews = () => {
    return commonNews
        .filter(item => moment(item.from).unix() < Date.now())
        .map((item, idx) => {
          return <div className="info" key={idx}><i className="fa fa-info-circle" />{item.content}</div>
        });
  };

  const renderEmail = () => {
    if (!competition) {
      return t('NOT_SELECTED');
    }

    return <a href={'mailto:' + competition.email} target="_blank" rel="noopener noreferrer">{competition.email}</a>;
  };

  const renderRegistration = () => {
    const registrationFromMoment = moment(competition?.registration_from ? competition.registration_from : 0);
    const registrationUntilMoment = moment(competition?.registration_to ? competition.registration_to : 0);
    const now = moment();
    if (registrationFromMoment.isAfter(now)) {
      return (<React.Fragment>
        <strong>{t('REGISTRATION_OPEN_FROM')}</strong>{registrationFromMoment.format(t('DATE_FORMAT'))}<br/>
      </React.Fragment>);
    }
    else if (registrationUntilMoment.isAfter(now)) {
      return (<React.Fragment>
        <strong>{t('REGISTRATION_OPEN_UNTIL')}</strong>
        {registrationUntilMoment.format(t('DATE_FORMAT'))}
        <br/>
      </React.Fragment>);
    }
    return null;
  }

  return (
      <div className={styles.Home}>
        {renderRegistered(useQuery().get('registered'))}
        {renderNews()}
        {renderText()}
        <div className={styles.competition}>
          <h2 dangerouslySetInnerHTML={{__html: t('HOME_TEXT')}} />
          <p dangerouslySetInnerHTML={{__html: t('HOME_COMPETITION_HEADER')}} />
          <strong>{t('CLUB')}</strong>{competition ? competition.club_name : t('NOT_SELECTED')}<br/>
          <strong>{t('SPORT')}</strong>{competition ? t(competition.sport.toUpperCase()) : t('NOT_SELECTED')}<br/>
          <strong>{t('COMPETITION')}</strong>{competition ? competition.name : t('NOT_SELECTED')}<br/>
          <strong>{t('MANAGER')}</strong>{competition ? competition.manager : t('NOT_SELECTED')}<br/>
          <strong>{t('EMAIL_ADDRESS')}</strong>{renderEmail()}<br/>
          {renderRegistration()}<br />
          <input
              type="button"
              value={'' + t('SELECT_COMPETITION')}
              className={styles.button}
              onClick={() => setShowSelectPopup(true)}
          />
          {isRegistrationOpen() && <Route render={({history}) => (
              <input
                  type="button"
                  value={'' + t('REGISTER')}
                  className={cn(styles.lastButton, 'bright')}
                  onClick={() => history.push(ROUTES.REGISTER, { competition})}
              />
          )} />}
          {isCompetitionActive() && <Route render={({history}) => (
              <input
                  type="button"
                  value={'' + t('ENTER_RESULT')}
                  className={cn(styles.lastButton, 'bright')}
                  onClick={() => history.push(ROUTES.CONTESTANT_MATCHES)}
              />
          )} />}
        </div>
        <div className={styles.signup}>
          <p dangerouslySetInnerHTML={{__html: t('SIGN_UP_EXPL')}} />
          <Route render={({history}) => (
              <input
                  type="button"
                  value={'' + t('SIGN_UP')}
                  className="signup"
                  onClick={() => history.push(ROUTES.SIGN_UP)}
              />
          )} />
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        {showSelectPopup
            ? <SelectCompetition
                onClose={() => setShowSelectPopup(false)}
            />
                : null}
      </div>
  );
};

export default Home;