import {Dispatch} from 'redux';
import moment from 'moment';

import * as types from './types';
import * as api from '../../api';

const constructAction = (type: string, payload: any) => ({ type, payload });

export const addMatches = (competitionId: number, group: string) => {
  return async (dispatch: Dispatch) => {
    const matches = await api.get(`matches/group/${group}`);
    dispatch(constructAction(types.ADD_MATCHES, matches));
  }
};

export const addMatchesPerDay = (competitionId: number, day: Date) => {
  return async (dispatch: Dispatch) => {
    const date = moment(day).format('YYYY-MM-DD');
    const matches = await api.get(`matches/${competitionId}/day/${date}`);
    const matchesPerDay = {
      day: moment(day).startOf('day').format('YYYY-MM-DD'),
      matches
    };
    dispatch(constructAction(types.ADD_MATCHES_PER_DAY, matchesPerDay));
  }
};

export const clearMatches = () => {
  return (dispatch: Dispatch) => {
    dispatch((constructAction(types.CLEAR_MATCHES, null)));
  }
};

export const getClubs = () => {
  return (dispatch: Dispatch) => {
    api.get(`clubs`)
        .then(clubs => dispatch(constructAction(types.GET_CLUBS, clubs)));
  }
};

export const getCompetition = (competitionId: number) => {
  return (dispatch: Dispatch) => {
    api.get(`competitions/${competitionId}`)
        .then(competition => dispatch(constructAction(types.GET_COMPETITION, competition)));
  }
};

export const getGroups = (competitionId: number) => {
  return async (dispatch: Dispatch) => {
    const groups = await api.get(`groups/${competitionId}`);
    dispatch(constructAction(types.GET_GROUPS, groups))
  }
};

export const getCommonNews = () => {
  return async (dispatch: Dispatch) => {
    // const news = await api.get('news');
    // dispatch(constructAction(types.GET_COMMON_NEWS, news));
  }
};

export const storePlayer = (player: types.Player | null) => {
  return (dispatch: Dispatch) => {
    dispatch(constructAction(types.STORE_PLAYER, player));
  }
};

export const getMatchesForPlayers = (competitionId: number, players: types.Player[]) => {
  return async (dispatch: Dispatch) => {
    const registrations = await getUserRegistrations(competitionId, players);
    const matches = [];
    for (const registration of registrations) {
      const currentMatches = await api.get(`matches/${competitionId}/registration/${registration.id}`);
      matches.push(...currentMatches);
    }
    dispatch(constructAction(types.GET_USER_MATCHES, matches))
  }
};

const getUserRegistrations = async (competitionId: number, players: types.Player[]) => {
  const registrations: types.Registration[] = [];
  for (const player of players) {
    const currentRegistrations = await api.get(`registrations/${competitionId}/player/${player.id}`);
    registrations.push(...currentRegistrations);
  }
  return registrations;
};

export const updateMatch = (match: types.Match) => {
  return (dispatch: Dispatch) => {
    dispatch(constructAction(types.UPDATE_MATCH, match));
  }
};

/*export const setClubId = (clubId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(constructAction(types.SET_CLUB_ID, clubId));
  }
};*/