import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import { useHistory } from 'react-router-dom';

import Registration, {SelectedRegistration} from 'components/Contestant/Registration';
import RegistrationAvailability from 'components/Contestant/RegistrationAvailability';

import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import * as types from 'store/db/types';
import * as api from 'api';
import {User} from 'store/auth/types';
import Loading from 'components/Common/Loading';

import styles from './Register.module.scss';

const Register = () =>  {
  const {t} = useTranslation();
  const history = useHistory();

  const user: User = useSelector((state: any) => state.auth.user);
  const competition: types.Competition | null = useSelector((state: any) => state.db.competition);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [registration1, setRegistration1] = useState<SelectedRegistration>();
  const [registration2, setRegistration2] = useState<SelectedRegistration>();
  const [availability, setAvailability] = useState<types.Availability>();
  const [competitionTypes, setCompetitionTypes] = useState<types.CompetitionType[]>([]);

  useEffect(() => {
    (async () => {
      if (competition && user) {
        // Get current registrations for player
        const competitionTypes = await api.get(`competitions/${competition.id}/types`);
        setCompetitionTypes(competitionTypes.filter((item: types.CompetitionType) => {
          if (user.player.gender === 1) {
            return types.isCompetitionTypeForMen(item.name);
          } else {
            return types.isCompetitionTypeForWomen(item.name);
          }
        }));

        const registrations: types.Registration[] = await api.get(`registrations/${competition.id}/player/${user.player.id}`);
        registrations.forEach((registration, idx) => {
          let setRegistration;
          if (idx === 0) {
            setRegistration = setRegistration1;
          } else {
            setRegistration = setRegistration2;
          }
          if (registration.players[0].id !== user.player.id) {
            setRegistration({
              competitionType: registration.competition_type.id || undefined,
              knownPartner: registration.players[0]
            })
          } else if (registration.players.length > 1) {
            setRegistration({
              competitionType: registration.competition_type.id || undefined,
              knownPartner: registration.players[1]
            })
          } else {
            setRegistration({
              competitionType: registration.competition_type.id || undefined,
              unknownPartner: registration.partner || undefined
            })
          }
        });
      }
      setLoading(false);
    })();
  }, [competition, user]);

  const onChangeRegistration = (nr: number, registration?: SelectedRegistration) => {
    if (nr === 1) {
      setRegistration1(registration);
    } else {
      setRegistration2(registration);
    }
  }

  const onChangeAvailability = (availability: types.Availability) => {
    setAvailability(availability);
  }

  const isValid = () => {
    return (registration1 && registration1.competitionType && (!types.isCompetitionTypeWithPermanentPartner(
        competitionTypes.find(item => item.id === registration1.competitionType)?.name)
        || registration1.knownPartner !== undefined || registration1.unknownPartner !== undefined))
        && (!registration2 || (registration2.competitionType && (!types.isCompetitionTypeWithPermanentPartner(
            competitionTypes.find(item => item.id === registration2.competitionType)?.name)
            || registration2.knownPartner !== undefined || registration2.unknownPartner !== undefined)));
  }

  const onSave = async () => {
    if (competition) {
      try {
        await api.post(`registrations/${competition.id}/player`, {player: user.player, registration1, registration2, availability});
        toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
        history.goBack();
      } catch (e) {
        toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
      }

    }
  }

  const renderRegistration = (competition: types.Competition, player: types.Player) => {
    return (<React.Fragment>
      <Registration
          competition={competition}
          player={player}
          competitionTypes={competitionTypes.filter(item => item.id !== registration2?.competitionType)}
          nr={1}
          registration={registration1}
          required
          onChange={(registration) => onChangeRegistration(1, registration)}
      />
      <Registration
          competition={competition}
          player={player}
          competitionTypes={competitionTypes.filter(item => item.id !== registration1?.competitionType)}
          nr={2}
          registration={registration2}
          onChange={(registration) => onChangeRegistration(2, registration)}
      />
      {competition && competition.use_availability && <RegistrationAvailability
        competitionId={competition.id}
        playerId={player.id}
        onChange={(availability) => onChangeAvailability(availability)}
      />}
      <input
          type="submit"
          value={'' + t('REGISTER')}
          disabled={!isValid()}
          onClick={onSave}
      />
      <input
          type="button" value={'' + t('CANCEL')}
          className="lastButton second"
          onClick={() => history.goBack()}
      />
    </React.Fragment>);
  }

  if (!competition) {
    return null;
  }

  if (user.player.clubs.indexOf(competition.club_id) === -1) {
    toast(t('NO_CLUB_ACCESS'), {type: toast.TYPE.WARNING});
    history.goBack();
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <div>
        <Breadcrumb title={'REGISTER'} />
        <p dangerouslySetInnerHTML={{__html: t('REGISTER_TEXT')}} />
        <p className={styles.competition}>{competition.club_name}<br />{competition.name}</p>
        {renderRegistration(competition, user.player)}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Register;
