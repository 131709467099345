import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import {toast} from 'react-toastify';

import * as dbTypes from '../components/Firebase/types';
import Breadcrumb from '../components/Breadcrumb';
import AdSense from '../components/AdSense';
import {Competition} from '../store/db/types';
import * as api from '../api';
import * as helper from '../components/Helper';

interface Props {
  competition: Competition | null;
}

const Contact = ({competition}: Props) => {
  const {t} = useTranslation();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [destinationError, setDestinationError] = useState<boolean>(false);
  const [subjectError, setSubjectError] = useState<boolean>(false);
  const [remarkError, setRemarkError] = useState<boolean>(false);
  const [captchaError, setCaptchaError] = useState<boolean>(false);

  const onSend = () => {
    const requiredError = !name || !email || !destination || !subject || !remark || !captcha;
    if (requiredError) {
      setNameError(!name);
      setEmailError(!email);
      setDestinationError(!destination);
      setSubjectError(!subject);
      setRemarkError(!remark);
      setCaptchaError(!captcha);
      return;
    }
    const contact: dbTypes.Contact = { name, email, destination, subject, remark, datetime: new Date()};
    api.post('contact', contact)
        .then(() => {
          toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
          setName('');
          setEmail('');
          setDestination('');
          setSubject('');
          setRemark('');
        })
        .catch(() => toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR}));
  };

  return (
      <div>
        <Breadcrumb title={'CONTACT_TITLE'} />
        <p dangerouslySetInnerHTML={{__html: t('CONTACT_TEXT')}} />
        <input
            className={nameError ? 'errorInput' : undefined}
            type="text"
            value={name}
            placeholder={t('NAME')}
            required
            onChange={(event) => setName(event.target.value)}
        />
        {helper.RenderRequiredError(nameError)}
        <input
            className={emailError ? 'errorInput' : undefined}
            type="text"
            value={email}
            placeholder={t('EMAIL_ADDRESS')}
            required
            onChange={(event) => setEmail(event.target.value)}
        />
        {helper.RenderRequiredError(emailError)}
        <select
            className={destinationError ? 'errorInput' : undefined}
            value={destination}
            required
            onChange={(event) => setDestination(event.target.value)}
        >
          <option>{t('SELECT_DESTINATION')}</option>
          <option value={competition ? competition.email : ''} disabled={!competition?.id}>
            {t('COMPETITION_MANAGER')}
          </option>
          <option value="info@internecompetitie.nl">{t('INTERNECOMPETITIE_MANAGER')}</option>
        </select>
        {helper.RenderRequiredError(destinationError)}
        <input
            className={subjectError ? 'errorInput' : undefined}
            type="text"
            value={subject}
            placeholder={t('SUBJECT')}
            required
            onChange={(event) => setSubject(event.target.value)}
        />
        {helper.RenderRequiredError(subjectError)}
        <textarea
            className={remarkError ? 'errorInput' : undefined}
            value={remark}
            rows={5}
            placeholder={t('REMARK')}
            required
            onChange={(event) => setRemark(event.target.value)}
        />
        {helper.RenderRequiredError(remarkError)}
        <ReCAPTCHA sitekey="6LdstbYUAAAAABylvCWs2ReAbHoA3Jt9PsMs4z0j" onChange={(value) => setCaptcha(value)} />
        {helper.RenderRequiredError(captchaError)}
        <input
            type="button"
            value={'' + t('SEND')}
            onClick={onSend}
        />
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Contact;