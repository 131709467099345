import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

import ErrorMessageRequired from "../Validation/ErrorMessageRequired";

interface Props {
  name: string;
  placeholderKey: string,
  register: any;
  errors: any;
  validate?: any;
}

const Password = ({name, placeholderKey, register, errors, validate}: Props) => {

  const [t] = useTranslation();

  return (
    <Fragment>
      <input
        name={name}
        ref={register({required: true, validate: validate})}
        type="password"
        placeholder={`${t(placeholderKey)} *`}
      />
      <ErrorMessageRequired errors={errors} />
      {errors && name === 'passwordTwo' && errors?.message && <p className="error">{t(errors?.message)}</p>}
    </Fragment>
  )
}

export default Password;