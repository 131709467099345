import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './i18n.json';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      fallbackLng: 'nl',
      keySeparator: false,
      interpolation: {
        escapeValue: false
      }
    });

export default i18n;