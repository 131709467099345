export const ABOUT = '/about';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const AVAILABLE_COURTS = '/available-courts';
export const CONTESTANT_MATCHES = '/contestant/matches';
export const CONTACT = '/contact';
export const HOME = '/';
export const MAIL = '/mail';
export const MANAGE_ADMINISTRATORS = '/manage-administrators';
export const MANAGE_ADMINISTRATOR = '/manage-administrator';
export const MANAGE_COMPETITIONS = '/manage-competitions';
export const MANAGE_COMPETITION_TYPE = '/manage-competition-type';
export const MANAGE_COMPETITION_TYPES = '/manage-competition-types';
export const MANAGE_COMPETITION_OVERVIEW = '/manage-competition-overview';
export const MANAGE_COURT_SCHEDULE = '/manage-court-schedule';
export const MANAGE_CREATE_COURT_SCHEDULE = '/manage-create-court-schedule';
export const MANAGE_CREATE_MATCH_SCHEDULE = '/manage-create-match-schedule';
export const MANAGE_EDIT_COMPETITION = '/manage-edit-competition';
export const MANAGE_GROUPS = '/manage-groups';
export const MANAGE_MATCHES = '/manage-matches';
export const MANAGE_PLAYER = '/manage-player';
export const MANAGE_PLAYERS = '/manage-players';
export const MANAGE_REGISTRATIONS = '/manage-registrations';
export const MANAGE_REGULATIONS = '/manage-regulations';
export const MATCHES_DAY = '/matches-day';
export const MATCHES_GROUP = '/matches-group';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_RESET = '/reset-password';
export const REGULATIONS = '/regulations';
export const REGISTER = '/register';
export const REGISTRATIONS = '/registrations';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const TABLES = '/tables';
export const VERIFY_EMAIL = '/verify-email';
