import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import AdSense from 'components/AdSense';
import Breadcrumb from 'components/Breadcrumb';
import Select from 'components/Common/Select';
import Table, {Action, Icon, Row} from 'components/Common/Table';
import Confirm from 'components/Common/Confirm';

import * as api from 'api';
import * as types from 'store/db/types';
import * as utils from 'utils';
import EditRegistration from 'components/Registration/EditRegistration';

interface LocationState {
  competition: types.Competition;
}

const Registrations = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [registrations, setRegistrations] = useState<types.Registration[]>([]);
  const [competitionTypes, setCompetitionTypes] = useState<types.CompetitionType[]>([]);
  const [groups, setGroups] = useState<types.Group[]>([]);
  const [selectedCompetitionType, setSelectedCompetitionType] = useState<types.CompetitionType>();
  const [selectedRegistration, setSelectedRegistration] = useState<types.Registration>();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showEditRegistration, setShowEditRegistration] = useState<boolean>(false);
  const [rerender, setRerender] = useState<number>(0);

  useEffect(() => {
    (async () => {
      if (locationState && registrations.length === 0) {
          const [competitionTypes, regs, groups] = await Promise.all([
            api.get(`competitions/${locationState.competition.id}/types`),
            api.get(`registrations/all/${locationState.competition.id}`),
            api.get(`groups/${locationState.competition.id}`)
          ]);
          setCompetitionTypes(competitionTypes);
          setRegistrations(regs);
          setGroups(groups);
          if (!selectedCompetitionType && competitionTypes.length > 0) {
            setSelectedCompetitionType(competitionTypes[0]);
          }
      }
    })();
  }, [locationState, registrations.length, selectedCompetitionType]);

  const handleClick = (action: Action, item: types.Registration) => {
    setSelectedRegistration(item);
    if (action === Action.DELETE) {
      setShowConfirmDelete(true);
    }
    else if (action === Action.EDIT) {
      setShowEditRegistration(true);
    }
  };

  const handleDeleteRegistration = async () => {
    if (await api.remove(t, `registrations/${selectedRegistration?.id}`)) {
      const curRegistrations = registrations;
      setRegistrations(curRegistrations.filter(reg => reg.id !== selectedRegistration?.id));
      setShowConfirmDelete(false);
    }
  }

  const handleUpdateRegistration = (updatedRegistration: types.Registration) => {
    setShowEditRegistration(false);
    if (selectedRegistration) {
      const idx = registrations.findIndex(item => item.id === updatedRegistration.id);
      if (idx > -1) {
        registrations[idx] = updatedRegistration;
      }
    } else {
      registrations.push(updatedRegistration);
    }
    setRerender(rerender + 1);
  }

  const renderTable = () => {
    if (!selectedCompetitionType) {
      return null;
    }

    const rows: Row[] = registrations
        .filter(reg => reg.competition_type.id === selectedCompetitionType.id)
        .map(registration => {
          const icons: Icon[] = [];
          if (types.isCompetitionTypeWithPermanentPartner(selectedCompetitionType.name)) icons.push({action: Action.EDIT});
          icons.push({
            action: Action.DELETE,
            disabled: registration.group_id !== null,
            tooltip: registration.group_id !== null ? t('REGISTRATION_IN_GROUP') : undefined
          });

          const columns: string[] = [];
          columns.push(utils.getName(t, registration));
          const group = groups.find(item => item.id === registration.group_id);
          columns.push(group ? group.name : '');

          return { item: registration, columns, icons };
        });

    return (
      <Table
        header={[t('REGISTRATION'), t('GROUP')]}
        rows={rows}
        onClick={handleClick}
      />);
  };

  const renderPopups = () => {
    if (showConfirmDelete) {
      return <Confirm
        title={t('DELETE_REGISTRATION_TITLE')}
        message={t('DELETE_REGISTRATION_TEXT', utils.getName(t, selectedRegistration!))}
        onNo={() => setShowConfirmDelete(false)}
        onYes={handleDeleteRegistration}
      />
    }
    if (showEditRegistration && selectedCompetitionType) {
      return <EditRegistration
        competition={locationState.competition}
        competitionType={selectedCompetitionType}
        registration={selectedRegistration}
        onClose={() => setShowEditRegistration(false)}
        onSave={handleUpdateRegistration}
      />
    }
    return null;
  };

  return (
      <div>
        <div>
          <Breadcrumb competition={locationState?.competition} title={'MANAGE_REGISTRATIONS_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('MANAGE_REGISTRATIONS_TEXT')}} />
          <input
            type="button"
            value={'' + t('ADD')}
            className="button"
            disabled={!selectedCompetitionType}
            onClick={() => {setSelectedRegistration(undefined); setShowEditRegistration(true);}}
          />
          <input
              type="button"
              value={'' + t('BACK')}
              className="button"
              onClick={() => history.goBack()}
          />
          <div className="filter">
            <Select
                defaultValue={selectedCompetitionType && `${t(types.CompetitionTypeName[selectedCompetitionType.name])}`}
                placeholder={t('SELECT_COMPETITION_TYPE')}
                canSelectAll={false}
                options={competitionTypes.map((type: types.CompetitionType) => {
                  const key = type;
                  const value = `${t(types.CompetitionTypeName[type.name])}`;
                  return {key, value};
                })}
                onChange={(option: types.CompetitionType) => setSelectedCompetitionType(option)} />
          </div>
          {renderTable()}
        </div>
        {renderPopups()}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Registrations;