import axios from 'axios';
import {toast} from 'react-toastify';
import {TFunction} from 'i18next';

axios.defaults.baseURL = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:8000'
    : 'https://api.internecompetitie.nl';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

export const add = async (t: TFunction, url: string, data: any): Promise<any | null> => {
  try {
    const result = await post(url, data);
    toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
    return result;
  } catch (e) {
    toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
    return null;
  }
}

export const update = async (t: TFunction, url: string, data: any): Promise<boolean> => {
  try {
    await put(url, data);
    toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
    return true;
  } catch (e) {
    toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
    return false;
  }
}

export const remove = async (t: TFunction, url: string): Promise<boolean> => {
  try {
    await del(url);
    toast(t('DELETE_SUCCESS'), {type: toast.TYPE.SUCCESS});
    return true;
  } catch (e) {
    toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
    return false;
  }
}

export const post = async (url: string, data: any) => {
  // const headers = {
    // ...getHeaders(),
    // 'X-CSRF-TOKEN':   document.getElementsByTagName("meta").csrftoken.getAttribute('content')
  // };
  // console.log(headers);
  try {
    const result = await axios.post(`/api/${url}`, data, { headers: getHeaders() });
    return result.data;
  } catch (e) {
    handleError(e);
  }
};

export const put = async (url: string, data: any) => {
  try {
    const result = await axios.put(`/api/${url}`, data, { headers: getHeaders() });
    return result.data;
  } catch (e) {
    handleError(e);
  }
};

export const patch = async (url: string, data: any) => {
  try {
    const result = await axios.patch(`/api/${url}`, data, { headers: getHeaders() });
    return result.data;
  } catch (e) {
    handleError(e);
  }
};

export const del = async (url: string) => {
  try {
  const result = await axios.delete(`/api/${url}`, { headers: getHeaders() });
  return result.data;
  } catch (e) {
    handleError(e);
  }
};

export const get = async (url: string, prefixWithApi = true) => {
  try {
    const result = await axios.get(`${prefixWithApi ? '/api' : ''}/${url}`, { headers: getHeaders() });
    return result.data;
  } catch (e) {
    handleError(e);
  }
};

export const transformErrorResponse = (error: any, t: TFunction): string => {
  const errors = error.response.data.errors;
  const errorKeys: string[] = [];
  for (const key in errors) {
    errors[key].forEach((element: string) => {
      errorKeys.push(t(`${key.toUpperCase()}_${element}`));
    });
  }

  if (errorKeys.length === 0 && error.response.data.message) {
    errorKeys.push(t(`${error.response.data.message.toUpperCase()}`))
  }

  if (errorKeys.length === 0) {
    return t('UNKNOWN_ERROR');
  }

  return errorKeys.join('\n');
};

const handleError = (error: any) => {
  if (error.response && error.response.status === 403) {
    window.location.href = '/signin';
  }
  console.error(error.response);
  throw error;
}

const getHeaders = () => {
  const auth = localStorage.getItem('auth');
  if (auth) {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',	
      'Authorization': auth
    };
  } else {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
  }
};