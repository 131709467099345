import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DbReducer} from 'store/db/reducer';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import Breadcrumb from 'components/Breadcrumb';
import SelectGroup from 'components/SelectGroup';
import AdSense from 'components/AdSense';
import RegistrationsList from 'components/Mail/RegistrationsList';
import PlayersList from 'components/Mail/PlayersList';
import SendMailPopup from 'components/Mail/SendMailPopup';

import * as types from 'store/db/types';
import * as api from 'api';
import * as utils from 'utils';

import styles from 'containers/Manage/Mail/SendMail.module.scss';

const SendMail = (): JSX.Element => {
  const {t} = useTranslation();

  const [isShowpopup, setShowPopup] = useState<boolean>(false);
  const [isShowPlayers, setShowPlayers] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>('none');
  const [groups, setGroups] = useState<types.Group[]>([]);
  const [registrations, setRegistrations] = useState<types.Registration[]>([]);
  const [filter, setFilter] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<types.Player|types.Registration|'list'>();

  const competition: types.Competition | null = useSelector<DbReducer, types.Competition | null>(state => state.db.competition);

  useEffect(() => {
    (async () => {
      if (competition) {
        setGroups(await  api.get(`groups/${competition.id}`));
        setRegistrations(await api.get(`registrations/all/${competition.id}`));
      }
    })();
  }, [competition]);

  if (!competition) {
    return t('NO_COMPETITION_SELECTED');
  }

  const renderRegistrations = () => {
    return <RegistrationsList
        competition={competition}
        registrations={getRegistrations()}
        onClick={(registration: types.Registration) => {
          setSelectedItems(registration);
          setShowPopup(true);
        }}
    />;
  }

  const renderPlayers = () => {
    return <PlayersList
        competition={competition}
        players={getPlayers()}
        onClick={(player: types.Player) => {
          setSelectedItems(player);
          setShowPopup(true);
        }}
    />;
  }

  const getRegistrations = (): types.Registration[] => {
    let useRegistrations = registrations;
    if (selectedGroup !== 'none') {
      useRegistrations = registrations.filter(reg => reg.group_id === +selectedGroup)
    }
    if (filter) {
      useRegistrations = useRegistrations.filter(reg => utils.getName(t, reg).toLowerCase().indexOf(filter) >= 0);
    }
    return useRegistrations;
  }

  const getPlayers = (): types.Player[] => {
    const useRegistrations = getRegistrations();
    const players: types.Player[] = [];
    const playerIds: number[] = [];
    useRegistrations.forEach(reg => {
      reg.players.forEach(player => {
        if (!playerIds.includes(player.id)) {
          players.push(player);
          playerIds.push(player.id);
        }
      });
    });
    if (filter && isShowPlayers) {
      return players.filter(player => player.name.toLowerCase().indexOf(filter) >= 0);
    }
    return players;
  }

  const onSendMail = async (subject: string, content: string) => {
    const players: types.Player[] = (() => {
      if (selectedItems === 'list') {
        return getPlayers();
      } else if (selectedItems && 'players' in selectedItems) {
        return selectedItems.players;
      } else if (selectedItems && 'name' in selectedItems) {
        return [selectedItems];
      }
      return [];
    })();
    const playerIds = players.map(player => player.id);
  
    try {
      await api.post(`competitions/${competition.id}/mail`, { playerIds, subject, content });

      toast(t('SEND_MAIL_SUCCESS'), {type: toast.TYPE.SUCCESS});
      setShowPopup(false);
    } catch (e) {
      toast(t('SEND_MAIL_ERROR'), {type: toast.TYPE.ERROR})
    }
  }

  return (
      <div>
        <div>
          <Breadcrumb competition={competition} title={'SEND_MAIL_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('SEND_MAIL_TEXT')}} />
          <p>{t('FILTER_LIST')}</p>
          <div className="filter">
            <SelectGroup groups={groups} canSelectAllGroups onChange={group => setSelectedGroup(group)} />
            <div className={styles.show}>
              <input type="checkbox" id="show" onChange={() => setShowPlayers(!isShowPlayers)} />
              <label htmlFor="show">{t('SHOW_PLAYERS_OVERVIEW')}</label>
            </div>
            <input onChange={(event) => setFilter(event.target.value)} placeholder={t('FILTER_NAME')} />
            <input type="button" value={`${t('SEND_MAIL_FOR_LIST')}`} onClick={() => {
                setSelectedItems('list');
                setShowPopup(true);
              }}
            />
          </div>
          {!isShowPlayers && renderRegistrations()}
          {isShowPlayers && renderPlayers()}
        </div>
        {isShowpopup && <SendMailPopup onClose={() => setShowPopup(false)} onSendMail={onSendMail} />}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default SendMail;