import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import * as api from '../../api';

import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';
import ErrorMessage from 'components/Validation/ErrorMessage';

interface Form {
  currentPassword: string;
  passwordOne: string;
  passwordTwo: string;
}

const PasswordChangeForm = () => {

  const {t} = useTranslation();

  const {handleSubmit, errors, register, getValues} = useForm({
    mode: 'onBlur',
  });

  const onSave = async (data: Form) => {
    try {
      await api.put('auth/password', {"current-password": data.currentPassword, "new-password": data.passwordOne});

      toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});

    } catch (e) {
      toast(t(e.response.data), {type: toast.TYPE.ERROR});
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <div className="field">
        <ErrorMessageRequired errors={errors.currentPassword} />
        <input type="password" id="currentPassword" name="currentPassword" ref={register({required: true})} placeholder={`${t('CURRENT_PASSWORD')}`} />
        <label htmlFor="currentPassword">{`${t('CURRENT_PASSWORD')} *`}</label>
      </div>
      <div className="field">
        <ErrorMessageRequired errors={errors.passwordOne} />
        <input
         type="password"
         id="passwordOne"
         name="passwordOne"
         ref={register({required: true})}
         placeholder={`${t('NEW_PASSWORD')}`} />
        <label htmlFor="passwordOne">{`${t('NEW_PASSWORD')} *`}</label>
      </div>
      <div className="field">
        <ErrorMessageRequired errors={errors.passwordTwo} />
        <ErrorMessage errorType="validate" errors={errors.passwordTwo} message={t('VALIDATION_EQUAL_PASSWORD_FIELDS')} />
        <input
          type="password"
          id="passwordTwo"
          name="passwordTwo"
          ref={register({required: true, validate: () => getValues("passwordOne") === getValues("passwordTwo")})}
          placeholder={`${t('NEW_PASSWORD_AGAIN')}`} />
        <label htmlFor="passwordTwo">{`${t('NEW_PASSWORD_AGAIN')} *`}</label>
      </div>
      <input type="submit" value={'' + t('SAVE')} />
    </form>
  );
}

export default PasswordChangeForm;