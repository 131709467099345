import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

import * as utils from 'utils';
import * as ROUTES from 'constants/routes';
import SignOutButton from 'components/Auth/SignOut';
import Racket from 'images/racket.png';

import './Navigation.scss';

const Navigation = () => {
  const user = useSelector((state: any) => state.auth.user);
  const competition = useSelector((state: any) => state.db.competition);

  const {t} = useTranslation();

  const renderNavigationAll = (isTop: boolean) => (
      <Fragment>
        <Link to={ROUTES.HOME} onClick={closeNav} style={{padding: '5px 30px'}}>
          <img alt={t('HOME')} src={Racket} height={36}/>
        </Link>
        <div className="dropdown">
          <button className="dropBtn" onClick={(event) => toggleMenu(event)}>{t('OVERVIEW')}
            <i className="fa fa-caret-down" />
          </button>
          <div className="dropdown-content">
            {renderLink(ROUTES.TABLES, 'TABLES', t)}
            {renderLink(ROUTES.MATCHES_GROUP, 'GROUP_MATCHES', t)}
            {renderLink(ROUTES.MATCHES_DAY, 'DAY_MATCHES', t)}
            {isTop && <hr />}
            {renderLink(ROUTES.AVAILABLE_COURTS, 'AVAILABLE_COURTS', t)}
            {isTop && <hr />}
            {renderLink(ROUTES.REGISTRATIONS, 'REGISTRATIONS', t)}
          </div>
        </div>
        <div className="dropdown">
          <button className="dropBtn" onClick={(event) => toggleMenu(event)}>{t('CONTESTANT')}
            <i className="fa fa-caret-down" />
          </button>
          <div className="dropdown-content">
            {renderLink(ROUTES.CONTESTANT_MATCHES, 'MY_MATCHES', t)}
          </div>
        </div>
        {renderNavigationManager()}
        {renderLink(ROUTES.REGULATIONS, 'REGULATIONS', t)}
        {renderLink(ROUTES.CONTACT, 'CONTACT', t)}
        {renderLink(ROUTES.ABOUT, 'ABOUT', t)}
      </Fragment>
  );

  const renderNavigationManager = () => {
    if (!utils.isManager(user, competition)) return null;
    return (
        <div className="dropdown">
          <button className="dropBtn" onClick={(event) => toggleMenu(event)}>{t('MANAGE')}
            <i className="fa fa-caret-down"/>
          </button>
          <div className="dropdown-content">
            {renderLink(ROUTES.MANAGE_COMPETITIONS, 'COMPETITIONS', t)}
            {renderLink(ROUTES.MANAGE_MATCHES, 'MATCHES', t)}
            {renderLink(ROUTES.MANAGE_PLAYERS, 'PLAYERS', t)}
            {renderLink(ROUTES.MAIL, 'SEND_MAIL', t)}
          </div>
        </div>
    );
  };

  const renderNavigationAuth = () => (
      <div className="dropdown auth">
        <button className="dropBtn" onClick={(event) => toggleMenu(event)}>
          <i className="fa fa-user" style={{fontSize: '22px'}}/>
        </button>
        <div className="dropdown-content">
          {renderLink(ROUTES.ACCOUNT, 'ACCOUNT', t)}
          <SignOutButton onClick={closeNav} />
        </div>
      </div>
  );

  const renderNavigationNonAuth = (t: TFunction) => (
      <Fragment>
        {renderLink(ROUTES.SIGN_IN, 'LOGIN', t, {float: 'right'})}
      </Fragment>
  );

  const openNav = () => {
    const sideNav = document.getElementById('sideNav');
    if (sideNav) sideNav.style.width = '250px';
  };

  const closeNav = () => {
    const myNav = document.getElementById('sideNav');
    if (myNav) myNav.style.width = '0';
  };

  const toggleMenu = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const content: any = evt.currentTarget.nextElementSibling;
    if (content && content.style.display === 'block') {
      content.style.display = 'none';
    } else {
      content.style.display = 'block';
    }
  };

  const renderLink = (path: string, key: string, t: TFunction, style?: any) => (
      <Link to={path} onClick={closeNav} style={style}>{t(key)}</Link>
  );

  return (
  <Fragment>
    <div className="topnav" id="topNav">
      {renderNavigationAll(true)}
      {user ? renderNavigationAuth() : renderNavigationNonAuth(t)}
      <button className="icon fa fa-bars" onClick={openNav}/>
    </div>
    <div className="sidenav" id="sideNav">
      <button className="closeBtn" onClick={closeNav}>&times;</button>
      {renderNavigationAll(false)}
      {user ? renderNavigationAuth() : renderNavigationNonAuth(t)}
    </div>
  </Fragment>
  );
};

export default Navigation;