import React, { Fragment } from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useForm} from "react-hook-form";

import * as api from '../../api';
import * as ROUTES from 'constants/routes';

import Breadcrumb from 'components/Breadcrumb';
import Password from 'components/Auth/Password';

import styles from 'containers/Auth/SignUp.module.scss';

interface Form {
  passwordOne: string;
  passwordTwo: string;
}

const PasswordForget = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { register, handleSubmit, getValues, errors } = useForm<Form>({mode: "onBlur"});

  const token = location.pathname.split('/').pop();
  const name = new URLSearchParams(location.search).get('username');

  const onSubmit = (data: Form) => {
    api.post(`auth/reset-password`, { token, name, password: data.passwordOne, password_confirmation: data.passwordTwo })
      .then(() => {
        toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
        history.push(ROUTES.SIGN_IN);
      })
      .catch(error => {
        toast(t(error.response.data), {type: toast.TYPE.ERROR});
      });
  };

  const validatePasswords = {
    passwordsEqual: (value :any) => (value === getValues().passwordOne || 'VALIDATION_EQUAL_PASSWORD_FIELDS')
  };

  return (
    <Fragment>
      <Breadcrumb title="RESET_PASSWORD" />
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <p dangerouslySetInnerHTML={{__html: t('RESET_PASSWORD_TEXT')}} />
            <div className={styles.form}>
              <Password name="passwordOne" placeholderKey="PASSWORD"
                        register={register} errors={errors.passwordOne}/>
              <Password name="passwordTwo" placeholderKey="PASSWORD_CONFIRM"
                        register={register} errors={errors.passwordTwo} validate={validatePasswords}/>
              <input type="submit" value={t('RESET_PASSWORD')!} />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default PasswordForget;
