import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import AdSense from '../../components/AdSense';

import About1 from 'images/about1.jpg';
import About2 from 'images/about2.jpg';
import Overview from 'images/overview.png';

import styles from './About.module.scss';

const About = () =>  {
  return (
      <div>
        <Breadcrumb title={'ABOUT_TITLE'} />
        <h1>Welkom op de&nbsp;<em><strong>Racket Competitie</strong></em>&nbsp;website!</h1>
        <div className={styles.div1}>
        <img alt="" src={About1} style={{float: 'right', marginLeft: '1em'}} />
        <h2>Eenvoudig <em>gratis</em> interne competities organiseren</h2>
        <p>Organiseert u binnen uw vereniging interne tennis en/of padel competities? Of bent u van plan dat deze te gaan organiseren?<br />Kost het uw vrijwilliger ook zoveel werk?</p>
        <p>Met onze website nemen wij een hoop werk uit de handen van uw vrijwilliger(s).</p>
        </div>
        <div className={styles.div2}>
        <img alt="" src={Overview} style={{float: 'left', marginRight: '2em'}} width={500} />
        <h2>Het aanmaken van een competitie</h2>
        <p>Uw vrijwilliger kan op een eenvoudige manier een competitie aanmaken via het volgende proces.</p>
        <ul>
        <li>Hij/zij maakt een nieuwe competitie aan voor een specifieke sport (tennis of padel)</li>
        <li>Hij/zij geeft aan in welke onderdelen deelgenomen kan worden</li>
        <li>Hij/zij genereert een baanschema op een eenvoudige manier</li>
        <li>Spelers kunnen zich vervolgens aanmelden voor de competitie</li>
        <li>Na de inschrijfperiode kunnen de deelnemers ingedeeld worden in poules</li>
        <li>Als laatste kunnen vanuit deze poules automatisch wedstrijden worden gegenereerd</li>
        </ul>
        <p>Als de wedstrijden zijn gegenereerd kunnen de wedstrijd uitslagen ingegeven worden door de deelnemers.<br />De deelnemers kunnen via de website zien wanneer ze moeten spelen en wat de poule standen zijn.</p>
        </div>
        <div className={styles.div1}>
        <img alt="" src={About2} style={{float: 'right', marginLeft: '1em'}} width={300} />
        <h2>Welke onderdelen zijn er beschikbaar</h2>
        <p>De competitie kan gespeeld worden met de volgende onderdelen</p>
        <ul>
        <li>Dames enkel</li>
        <li>Heren enkel</li>
        <li>Dames dubbel met vaste partner</li>
        <li>Heren dubbel met vaste partner</li>
        <li>Gemengd dubbel met vaste partner</li>
        <li>Dubbel met vaste partner (hierbij kan een dubbel dus bestaan uit 2 heren, 2 dames of een dame en een heer)</li>
        <li>Heren dubbel met wisselende partners (hierbij speelt een deelnemer iedere wedstrijd met een andere partner en worden individueel punten toegekend)</li>
        <li>Dames dubbel met wisselende partners</li>
        <li>Dubbel met wisselende partners</li>
        </ul>
        <p>Een wedstrijd in een onderdeel kan de volgende telling gebruiken</p>
        <ul>
        <li>Twee gespeelde sets</li>
        <li>Twee gespeelde sets, bij 6-6 is het gelijkspel in de set</li>
        <li>Twee gewonnen sets (er kunnen dus eventueel 3 sets gespeeld worden)</li>
        <li>Games doortellen</li>
        </ul>
        </div>
        <h2>Interesse?</h2>
        <p>Bent u ge&iuml;nteresseerd om uw competitie <i>gratis</i> via onze website te organiseren? Neem dan contact op via het&nbsp;<a href="https://www.internecompetitie.nl/contact">contact</a>&nbsp;formulier of stuur een mail naar&nbsp;<a href="mailto:info@internecompetitie.nl">info@internecompetitie.nl</a>.</p>        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default About;
