import React, {Component} from 'react';
import {
  BrowserRouter as Router, Redirect,
  Route, Switch
} from 'react-router-dom';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {ToastContainer} from 'react-toastify';
import ReactGA from 'react-ga';

import * as api from 'api';

// Account pages
import AdminPage from 'containers/Admin';
import PasswordForgetPage from 'containers/Auth/PasswordForget';
import PasswordResetPage from 'containers/Auth/PasswordReset';
import SignUpPage from 'containers/Auth/SignUp';
import SignInPage from 'containers/Auth/SignIn';
import VerifyEmailPage from 'containers/Auth/VerifyEmail';

// Common pages
import AboutNl from 'containers/About/About_nl';
import Contact from 'containers/Contact';
import HomePage from 'containers/Home';
import Navigation from 'components/Navigation/Navigation';

// Contestant pages
import AccountPage from 'containers/Contestant/Account';
import Matches from 'containers/Contestant/Matches';
import Register from 'containers/Contestant/Register';

// Manage pages
import ManageAdministrators from 'containers/Manage/Competitions/Administrators';
import ManageCompetitions from 'containers/Manage/Competitions/Competitions';
import ManageCompetition from 'containers/Manage/Competitions/EditCompetition';
import ManageCompetitionOverview from 'containers/Manage/Competitions/Overview';
import ManageCourtsSchedule from 'containers/Manage/Competitions/CourtsSchedule';
import ManageCreateCourtSchedule from 'containers/Manage/Competitions/CreateCourtSchedule';
import ManageCreateMatchSchedule from 'containers/Manage/Competitions/CreateMatchSchedule';
import ManageGroups from 'containers/Manage/Competitions/Groups';
import ManageMatches from 'containers/Manage/Matches';
import ManageCompetitionTypes from 'containers/Manage/Competitions/CompetitionTypes';
import ManageCompetitionType from 'containers/Manage/Competitions/EditCompetitionType';
import ManageRegistrations from 'containers/Manage/Competitions/Registrations';
import ManageRegulations from 'containers/Manage/Regulations';

import ManagePlayers from 'containers/Manage/Players/Players';
import ManagePlayer from 'containers/Manage/Players/Player';

import SendMail from 'containers/Manage/Mail/SendMail';

// Overview pages
import AvailableCourts from 'containers/Overview/AvailableCourts';
import MatchesGroup from 'containers/Overview/MatchesGroup';
import MatchesDay from 'containers/Overview/MatchesDay';
import Regulations from 'containers/Regulations/Regulations';
import Registrations from 'containers/Overview/Registrations';
import Tables from 'containers/Overview/Tables';

import * as ROUTES from 'constants/routes';
import {storeUser} from 'store/auth/actions';
import PrivateRoute from 'components/PrivateRoute';
import {User} from 'store/auth/types';

import './App.scss';
import * as actions from 'store/db/actions';
import {DbReducer} from 'store/db/reducer';

interface DispatchProps {
  getCompetition: (competitionId: number) => void;
  getGroups: (competitionId: number) => void;
  storeUser: (user: User | null) => void;
}
interface Props extends DispatchProps, DbReducer {
  user: User | null;
}
interface State {
  listener: any | null;
}

class App extends Component<Props, State> {

  state = {
    listener: null
  };

  componentDidMount() {
    api.get('sanctum/csrf-cookie', false);
    const token = localStorage.getItem('auth');
    if (token) {
      api.get('auth/').then(user => {
        this.props.storeUser(user);
      }).catch(e => localStorage.removeItem('auth'));
    }

    let competitionId = localStorage.getItem('competitionId');
    if (!competitionId || isNaN(+competitionId)) {
      competitionId = '1';
      localStorage.setItem('competitionId', competitionId);
    }
    if (competitionId) {
      this.props.getGroups(+competitionId);
      this.props.getCompetition(+competitionId);
    }
  }

  renderComponent(path: string, component: any) {
    ReactGA.pageview(path);
    return component;
  }

  render() {
    if (localStorage.getItem('competitionId') && !this.props.db.competition) return <div className="lds-dual-ring"/>;

    return (
      <Router>
        <div>
          <Navigation />

          <div className="main" id="main">
            <Switch>
              <Route exact path={ROUTES.HOME} render={() => this.renderComponent(ROUTES.HOME, <HomePage/>)}/>
              <PrivateRoute path={ROUTES.ACCOUNT} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.ACCOUNT, <AccountPage/>)}/>
              <PrivateRoute path={ROUTES.ADMIN} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.ADMIN, <AdminPage/>)}/>
              <PrivateRoute path={ROUTES.CONTESTANT_MATCHES} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.CONTESTANT_MATCHES, <Matches/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_ADMINISTRATORS} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_ADMINISTRATORS, <ManageAdministrators/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_COMPETITIONS} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_COMPETITIONS, <ManageCompetitions/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_COMPETITION_OVERVIEW} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_COMPETITION_OVERVIEW, <ManageCompetitionOverview/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_EDIT_COMPETITION} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_EDIT_COMPETITION, <ManageCompetition/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_COURT_SCHEDULE} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_COURT_SCHEDULE, <ManageCourtsSchedule/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_CREATE_COURT_SCHEDULE} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_CREATE_COURT_SCHEDULE, <ManageCreateCourtSchedule/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_CREATE_MATCH_SCHEDULE} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_CREATE_MATCH_SCHEDULE, <ManageCreateMatchSchedule/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_GROUPS} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_GROUPS, <ManageGroups/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_MATCHES} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.MANAGE_MATCHES, <ManageMatches/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_COMPETITION_TYPES} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <ManageCompetitionTypes/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_COMPETITION_TYPE} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <ManageCompetitionType/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_REGISTRATIONS} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <ManageRegistrations/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_REGULATIONS} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <ManageRegulations/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_PLAYERS} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <ManagePlayers/>)}/>
              <PrivateRoute path={ROUTES.MANAGE_PLAYER} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <ManagePlayer/>)}/>
              <PrivateRoute path={ROUTES.MAIL} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <SendMail/>)}/>
              <PrivateRoute path={ROUTES.REGISTER} authenticated={!!this.props.user}
                            render={() => this.renderComponent(ROUTES.HOME, <Register/>)}/>

              <Route path={ROUTES.ABOUT} render={() => this.renderComponent(ROUTES.HOME, <AboutNl/>)}/>
              <Route path={ROUTES.AVAILABLE_COURTS} render={() => this.renderComponent(ROUTES.AVAILABLE_COURTS, <AvailableCourts/>)}/>
              <Route path={ROUTES.CONTACT} 
                     render={() => { ReactGA.pageview(ROUTES.CONTACT); return <Contact competition={this.props.db.competition}/>}}/>
              <Route path={ROUTES.MATCHES_DAY} render={() => this.renderComponent(ROUTES.MATCHES_DAY, <MatchesDay/>)}/>
              <Route path={ROUTES.MATCHES_GROUP} render={() => this.renderComponent(ROUTES.MATCHES_GROUP, <MatchesGroup/>)}/>
              <Route path={ROUTES.PASSWORD_FORGET} render={() => this.renderComponent(ROUTES.PASSWORD_FORGET, <PasswordForgetPage/>)}/>
              <Route path={ROUTES.PASSWORD_RESET} render={() => this.renderComponent(ROUTES.PASSWORD_RESET, <PasswordResetPage/>)}/>
              <Route path={ROUTES.REGULATIONS} component={Regulations}/>
              <Route path={ROUTES.REGISTRATIONS} render={() => this.renderComponent(ROUTES.REGISTRATIONS, <Registrations/>)}/>
              <Route path={ROUTES.SIGN_UP} render={() => this.renderComponent(ROUTES.SIGN_UP, <SignUpPage/>)}/>
              <Route path={ROUTES.SIGN_IN} component={SignInPage}/>
              <Route path={ROUTES.TABLES} render={() => this.renderComponent(ROUTES.TABLES, <Tables/>)}/>
              <Route path={ROUTES.VERIFY_EMAIL} render={() => this.renderComponent(ROUTES.VERIFY_EMAIL, <VerifyEmailPage/>)}/>
              <Redirect to={ROUTES.HOME}/>
            </Switch>
          </div>

          <ToastContainer autoClose={5000}  />
          {/*<AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />*/}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  db: state.db
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    getCompetition: (competitionId: number) => dispatch(actions.getCompetition(competitionId)),
    getGroups: (competitionId: number) => dispatch(actions.getGroups(competitionId)),
    storeUser: (user: User|null) => dispatch(storeUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);