import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';

import 'moment/locale/nl';

interface Props {
  placeholder: string;
  ref?: any;
  style?: any;
  value?: string | Date;
  onChange?(date: Date): void;
}
const DatePicker = (props: Props) =>  {

  return (
      <DayPickerInput
          ref={props.ref}
          formatDate={MomentLocaleUtils.formatDate}
          parseDate={MomentLocaleUtils.parseDate}
          format="DD-MM-YYYY"
          placeholder={props.placeholder}
          style={props.style}
          value={props.value}
          onDayChange={(day) => { if (props.onChange) props.onChange(day) }}
      />
  );
}

export default DatePicker;
