import React from 'react';
import {Translation} from 'react-i18next';

export const RenderRequiredError = (isError: boolean) =>  {
  if (!isError) return null;
  return <Translation>{(t) => <div className="errorText">{t('ERROR_REQUIRED')}</div>}</Translation>
};

export const RenderCustomError = (isError: boolean, message: string) =>  {
  if (!isError) return null;
  return <Translation>{(t) => <div className="errorText">{t(message)}</div>}</Translation>
};

export const RenderCustomWarning = (isWarning: boolean, message: string, options?: any) =>  {
  if (!isWarning) return null;
  return <Translation>{(t) => <div className="warningText">{t(message, options)}</div>}</Translation>
};
