import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import moment from 'moment';

import Breadcrumb from 'components/Breadcrumb';
import SelectGroup from 'components/SelectGroup';
import AdSense from 'components/AdSense';
import MatchesList from 'components/Competition/MatchesList';
import DatePicker from 'components/DatePicker';

import * as types from 'store/db/types';
import * as api from 'api';
import {DbReducer} from 'store/db/reducer';

interface LocationState {
  competition: types.Competition;
}

const Matches = (): JSX.Element => {
  const {t} = useTranslation();
  const location = useLocation();
  const locationState = location.state as LocationState | null;

  const [selectedGroup, setSelectedGroup] = useState<string>('none');
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [matches, setMatches] = useState<types.Match[]>([]);
  const [groups, setGroups] = useState<types.Group[]>([]);

  const competition: types.Competition | null = useSelector<DbReducer, types.Competition | null>(state => state.db.competition);

  useEffect(() => {
    const loadMatches = async () => {
      if (matches.length === 0) {
        if (locationState) {
          setMatches(await api.get(`matches/${locationState.competition.id}`));
          setGroups(await api.get(`groups/${locationState.competition.id}`));
        }
        else if (competition) {
          setMatches(await api.get(`matches/${competition.id}`));
          setGroups(await api.get(`groups/${competition.id}`));
        }
      }
    }

    loadMatches();
  }, [competition, locationState, matches.length]);

  const activeComp = locationState?.competition || competition;
  if (!activeComp) {
    return t('NO_COMPETITION_SELECTED');
  }

  const onMatchesChange = async () => {
    if (locationState) {
      setMatches(await api.get(`matches/${locationState.competition.id}`));
      setGroups(await api.get(`groups/${locationState.competition.id}`));
    }
    else if (competition) {
      setMatches(await api.get(`matches/${competition.id}`));
      setGroups(await api.get(`groups/${competition.id}`));
    }
  }

  const renderGroup = () => {

    let useMatches = matches;
    if (selectedGroup !== 'none') {
      useMatches = matches.filter(match => match.group_id === +selectedGroup)
    }
    if (selectedDay) {
      useMatches = useMatches.filter(match => match.date_time && match.date_time.substr(0, 10) === selectedDay)
    }
    if (useMatches.length === 0) {
      return null;
    }
    return <MatchesList
        competition={activeComp}
        matches={useMatches}
        type="Manager"
        onChange={onMatchesChange}
    />;
  }

  return (
      <div>
        <div>
          <Breadcrumb competition={activeComp} title={'MANAGE_MATCHES_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('MANAGE_MATCHES_TEXT')}} />
          <p>{t('FILTER_LIST')}</p>
          <div className="filter">
            <SelectGroup groups={groups} canSelectAllGroups onChange={group => setSelectedGroup(group)} />
            <DatePicker
                placeholder={t('FILTER_ON_DATE')}
                onChange={(date) => date ? setSelectedDay(moment(date).format('YYYY-MM-DD')) : setSelectedDay(null)}
            />
          </div>
          {renderGroup()}
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Matches;