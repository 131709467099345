import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

import ErrorMessagePattern from "components/Validation/ErrorMessagePattern";
import ErrorMessageRequired from "../Validation/ErrorMessageRequired";

interface Props {
  register: any;
  errors: any;
  newLayout?: boolean;
}

const MobilePhoneNumber = ({register, errors, newLayout}: Props) => {

  const [t] = useTranslation();

  return (
    <Fragment>
      {newLayout && <ErrorMessageRequired errors={errors} />}
      {newLayout && <ErrorMessagePattern errors={errors} errorMsgKey="VALIDATION_FIELD_PHONE_NUMBER_PATTERN" />}
      <input
        name="mobile"
        id="mobile"
        ref={register(
          {required: true},
          {pattern: /(((\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i})
        }
        type="tel"
        placeholder={`${t('MOBILE_NUMBER')} *`}
      />
      {newLayout && <label htmlFor="mobile">{`${t('MOBILE_NUMBER')} *`}</label>}
      {!newLayout && <ErrorMessageRequired errors={errors} />}
      {!newLayout && <ErrorMessagePattern errors={errors} errorMsgKey="VALIDATION_FIELD_PHONE_NUMBER_PATTERN" />}
    </Fragment>
  )

}

export default MobilePhoneNumber;