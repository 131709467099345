import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {toast} from 'react-toastify';

import * as api from 'api';
import * as ROUTES from 'constants/routes';

import Email from 'components/Form/Email';
import AccountName from 'components/Auth/AccountName';
import Name from 'components/Form/Name';
import Gender from 'components/Form/Gender';
import PlayerLevel from 'components/Form/PlayerLevel';
import MobilePhoneNumber from 'components/Form/MobilePhoneNumber';
import PhoneNumber from 'components/Form/PhoneNumber';
import PlayerNumber from 'components/Form/PlayerNumber';
import Password from 'components/Auth/Password';
import Breadcrumb from 'components/Breadcrumb';
import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';
import Confirm from 'components/Common/Confirm';

import styles from './SignUp.module.scss';
import Club from 'components/Form/Club';

interface Form {
  name: string;
  gender: string;
  mobile: string;
  phone: string;
  playerNumber: number;
  club: number;
  levelDouble: number;
  levelSingle: number;
  levelPadel: number;
  email: string;
  accountName: string;
  password: string;
  passwordConfirmation: string;
  captchaToken: string;
}

const SignUp = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const { register, handleSubmit, getValues, setValue, errors } = useForm<Form>({mode: 'onBlur'});

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  
  useEffect(() => {
    register({name: 'captchaToken'}, {required: true});    
  });
 
  const onSubmit = async (data: Form) => {
    const email = await api.get(`auth/email-check/${data.email}`);
    if (email?.emailExists) {
      setShowConfirm(true);
    } else {
      await createUser(data);
    }
  };

  const createUser = async (data: Form) => {
    try {
      await api.post('auth/register', {
        ...data,
        passwordConfirmation: undefined,
        password_confirmation: data.passwordConfirmation
      });
      history.push(`${ROUTES.HOME}?registered=true`);
    } catch (error) {
      toast(api.transformErrorResponse(error, t), {type: toast.TYPE.ERROR});
    }
  };

  const validatePasswords = {
      passwordsEqual: (value: string) => (value === getValues().password || 'VALIDATION_EQUAL_PASSWORD_FIELDS'),
      passwordValid: (value: string) => (value.length >= 8 && /[A-Z]/.test(value) && /[0-9]/.test(value)) || 'VALIDATION_FIELD_PASSWORD'
  };

  const onVerifyCaptcha = (token: string | null) => {
    setValue('captchaToken', token);
  };

  const renderConfirm = () => {
    if (!showConfirm) return null;
    return (
      <Confirm
        title={t('EMAIL_ADDRESS_EXISTS_TITLE')}
        message={t('EMAIL_ADDRESS_EXISTS_TEXT')}
        onNo={() => setShowConfirm(false)}
        onYes={() => {
          setShowConfirm(false);
          createUser(getValues());
        }}
      />
    );
  }

  return (
    <Fragment>
      <Breadcrumb title='SIGN_UP' />
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <p dangerouslySetInnerHTML={{__html: t('SIGN_UP_TEXT')}} />
            <div className={styles.form}>
              <AccountName register={register} errors={errors.accountName}/>
              <Password name='password' placeholderKey='PASSWORD'
                        register={register} errors={errors.password}/>
              <Password name='passwordConfirmation' placeholderKey='PASSWORD_CONFIRM'
                        register={register} errors={errors.passwordConfirmation} validate={validatePasswords}/>
              <hr />
              <Name register={register} errors={errors.name}/>
              <Email register={register} errors={errors.email}/>
              <label>{t('GENDER')}</label>
              <Gender register={register} errors={errors.gender}/>
              <label>{t('CLUB')}</label>
              <Club register={register} errors={errors.club} />
              <label>{t('LEVEL_SINGLE')}</label>
              <PlayerLevel name='levelSingle' placeholderKey='LEVEL_SINGLE' disablePlaceholder
                            register={register} errors={errors.levelSingle}/>
              <label>{t('LEVEL_DOUBLE')}</label>
              <PlayerLevel  name='levelDouble' placeholderKey='LEVEL_DOUBLE' disablePlaceholder
                            register={register} errors={errors.levelDouble}/>
              <label>{t('LEVEL_PADEL')}</label>
              <PlayerLevel  name='levelPadel' placeholderKey='LEVEL_PADEL'
                            register={register} errors={errors.levelDouble}/>
              <MobilePhoneNumber register={register} errors={errors.mobile}/>
              <PhoneNumber register={register} errors={errors.phone}/>
              <PlayerNumber register={register} errors={errors.playerNumber}/>
              <ReCAPTCHA sitekey='6LdstbYUAAAAABylvCWs2ReAbHoA3Jt9PsMs4z0j' onChange={onVerifyCaptcha} />
              <ErrorMessageRequired errors={errors.captchaToken} />
              <input type='submit' value={`${t('SIGN_UP')}`} />
            </div>
          </div>
        </form>
      </div>
      { renderConfirm() }
    </Fragment>
  );
}

export default SignUp;
