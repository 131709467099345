import React, {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import moment from 'moment';

import * as types from '../../store/db/types';
import {getRegistrationName} from '../../utils';

interface Props extends WithTranslation {
  competition: types.Competition;
  match: types.Match;
  onSave(result: string, removeTime: boolean): void;
  onClose(): void;
}
interface State {
  remark: string | null;
  errMsg: string;
  removeTime: boolean;
}

class EditRemark extends Component<Props, State> {

  state = {
    remark: '',
    errMsg: '',
    removeTime: false
  };

  componentDidMount(): void {
    this.setState({remark: this.props.match.remark});
  }

  onSave = () => {
    const {remark, removeTime} = this.state;

    this.props.onSave(remark, removeTime);
  };

  render(): React.ReactNode {
    const { t, match, competition } = this.props;

    return (
        <div className="popup">
          <div className="header">{t('EDIT_REMARK_TITLE')}</div>
          <div className="content">
            <p>{t('EDIT_REMARK_TEXT')}</p>
            <p>{getRegistrationName(t, match, 'H') + ' - ' + getRegistrationName(t, match, 'A')}</p>
            <label style={{color: 'red'}}>{this.state.errMsg}</label>
            <input
                type="text"
                value={this.state.remark}
                placeholder={t('REMARK')}
                onChange={(event) => this.setState({remark: event.target.value})}
            />
            {competition.move_match !== 0 && !match.result && match.date_time && moment(match.date_time).year() !== 1970 &&
            <label className="checkbox">{t('REMOVE_MATCH_TIME')}
                <input type="checkbox" checked={this.state.removeTime}
                       onChange={() => this.setState({removeTime: !this.state.removeTime})}/>
                <span className="checkmark" />
              </label>
            }
            <input
                type="button"
                value={'' + t('SAVE')}
                className="button"
                onClick={this.onSave}
            />
            <input
                type="button" value={'' + t('CANCEL')}
                className="lastButton second"
                onClick={this.props.onClose}
            />
          </div>
        </div>
    );
  }
}

export default withTranslation()(EditRemark);