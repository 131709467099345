import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import * as api from '../../../api';
import * as types from '../../../store/db/types';
import Breadcrumb from '../../../components/Breadcrumb';
import SelectAdministrator from '../../../components/Competition/SelectAdministrator';

import AdSense from '../../../components/AdSense';
import {toast} from 'react-toastify';

interface LocationState {
  competition: types.Competition
}

const Administrators = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState

  const [administrators, setAdministrators] = useState<types.Player[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    updateAdministrators(locationState.competition.id);
  }, [locationState.competition.id]);

  const updateAdministrators = async (id: number) => {
    await api.get(`players/admin/${id}`)
        .then(types => setAdministrators(types));
  }

  const deleteAdministrator = async (id: number) => {
    if (!administrators || administrators?.length < 2) {
      toast(t('MINIMAL_ONE_ADMINISTRATOR_NEEDED'), {type: toast.TYPE.WARNING});
      return;
    }

    try {
      await api.del(`competitions/${locationState.competition.id}/admin/${id}`);
      await updateAdministrators(locationState.competition.id);
      toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
    }
  }

  return (
      <div>
        <Breadcrumb title={'ADMINISTRATORS'} />
        <input
            type="button"
            value={'' + t('NEW_ADMINISTRATOR')}
            className="button"
            onClick={() => setShowPopup(true)}
        />
        <input
            type="button"
            value={'' + t('BACK')}
            className="button"
            onClick={() => history.goBack()}
        />
        <div className="table">
          <table>
            <tbody>
            <tr>
              <th>{t('NAME')}</th>
              <th>{t('EMAIL')}</th>
              <th/>
            </tr>
            {administrators && administrators
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => {
                  return (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td className="icons">
                          <i className="fa fa-trash-alt tooltip"
                             onClick={() => deleteAdministrator(item.id)}>
                            <div>{t('DELETE')}</div>
                          </i>
                        </td>
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        {showPopup
            ? <SelectAdministrator
                competition={locationState.competition}
                onClose={() => {
                  updateAdministrators(locationState.competition.id);
                  setShowPopup(false);
                }}
            />
            : null}
      </div>
  );
};

export default Administrators;