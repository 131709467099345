import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useForm, useFieldArray} from 'react-hook-form';
import {toast} from 'react-toastify';

import styles from './Account.module.scss';

import * as api from 'api';
import * as types from 'store/db/types';

import Breadcrumb from 'components/Breadcrumb';
import AdSense from 'components/AdSense';
import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';
import ErrorMessage from 'components/Validation/ErrorMessage';
import Email from 'components/Form/Email';
import Name from 'components/Form/Name';
import PasswordChange from 'components/Auth/PasswordChange';

interface Form {
  name: string;
  email: string;
  gender: 1 | 2;
  mobile: string;
  phone: string;
  number: string;
  levelSingle: number;
  levelDouble: number;
  levelPadel: number;
  clubs: string[];
}

const EditCompetition = () => {
  const {t} = useTranslation();

  const [player, setPlayer] = useState<types.Player>();
  // const [playerClubs, setPlayerClubs] = useState<number[]>([]);
  const [clubs, setClub] = useState<types.Club[]>([]);

  const {handleSubmit, errors, control, register, setValue} = useForm({
    mode: 'onBlur',
  });

  const {fields, append, remove} = useFieldArray({
    control, name: 'clubs'
  });

  useEffect(() => {
    (async () => {
      setClub(await api.get('clubs'));
      const player: types.Player = await api.get('players');
      setPlayer(player);
      // setPlayerClubs(player.clubs);
      setValue('name', player.name);
      setValue('email', player.email);
      setValue('gender', player.gender);
      setValue('mobile', player.mobile);
      setValue('phone', player.phone);
      setValue('number', player.number);
      setValue('levelSingle', player.level_single);
      setValue('levelDouble', player.level_double);
      setValue('levelPadel', player.level_padel);
      remove();
      player.clubs.forEach((club) => append({value: `${club}`}));
    })();
  },[setValue, append, remove]);
 
  const onSave = async (data: Form) => {
    const savePlayer: types.Player = {
      id: player!.id,
      name: data.name,
      email: data.email,
      gender: data.gender,
      mobile: data.mobile,
      phone: data.phone,
      number: data.number,
      level_single: `${data.levelSingle}`,
      level_double: `${data.levelDouble}`,
      level_padel: data.levelPadel ? `${data.levelPadel}` : null,
      clubs: fields.map(field => field.value)
    };

    try {
      await api.put(`players`, savePlayer);
      toast(t('SAVE_SUCCESS'), {type: toast.TYPE.SUCCESS});
    } catch (e) {
      toast(t('SAVE_ERROR'), {type: toast.TYPE.ERROR});
    }
  };

  const validateClubs = () => {
    const exist: number[] = [];
    for (const field of fields) {
      if (exist.includes(field.value)) {
        return false;
      }
      exist.push(field.value);
    }
    return true;
  }

  const renderClubs = () => {
    return fields.map((field, index) => (
      <div className={styles.club} key={field.id}>
        <select
          key={field.id}
          name={`clubs[${index}].value`}
          ref={register({required: true, validate: validateClubs})}
          defaultValue={field.value}
          onChange={(event) => fields[index].value = event.target.value}
        >
          {clubs.map(club => <option key={club.id} value={club.id}>{club.name}</option>)}
        </select>
        {index < fields.length - 1 && <button className="fa fa-trash-alt" onClick={() => remove(index)} />}
      </div>
    ));
  };

  return (
      <div>
        <Breadcrumb title={'ACCOUNT'} />
        <div className={styles.form}>
          <div>
          <p dangerouslySetInnerHTML={{__html: t('ACCOUNT_TEXT')}} />
          <form onSubmit={handleSubmit(onSave)}>
            <div className="field">
              <Name register={register} errors={errors.name} newLayout />
            </div>
            <div className="field">
              <Email register={register} errors={errors.email} newLayout />
            </div>
            <div className="field">
              <ErrorMessageRequired errors={errors.gender} />
              <select
                name="gender"
                className="new"
                ref={register({required: true})}
              >
                <option value="1">{t('MALE')}</option>
                <option value="2">{t('FEMALE')}</option>
              </select>
              <label>{t('GENDER')}</label>
            </div>
            <div className="field">
              <input type="tel" id="mobile" name="mobile" ref={register()} placeholder={`${t('MOBILE_NUMBER')}`} />
              <label htmlFor="mobile">{t('MOBILE_NUMBER')}</label>
            </div>
            <div className="field">
              <input type="tel" id="phone" name="phone" ref={register()} placeholder={`${t('PHONE_NUMBER')}`} />
              <label htmlFor="phone">{t('PHONE_NUMBER')}</label>
            </div>
            <div className="field">
              <ErrorMessageRequired errors={errors.number} />
              <input name="number" id="number" ref={register({required: true})} placeholder={`${t('PLAYER_NUMBER')}`} />
              <label htmlFor="number">{`${t('PLAYER_NUMBER')} *`}</label>
            </div>
            <div className="field">
              <ErrorMessageRequired errors={errors.levelSingle} />
              <ErrorMessage errorType="min" errors={errors.levelSingle} message={t('VALIDATION_LEVEL_MIN')} />
              <ErrorMessage errorType="max" errors={errors.levelSingle} message={t('VALIDATION_LEVEL_MAX')} />
              <input type="number" min="1" max="9" id="levelSingle" name="levelSingle" ref={register({required: true, min: 1, max: 9})} placeholder={`${t('LEVEL_SINGLE')}`} />
              <label htmlFor="levelSingle">{`${t('LEVEL_SINGLE')} *`}</label>
            </div>
            <div className="field">
              <ErrorMessageRequired errors={errors.levelDouble} />
              <ErrorMessage errorType="min" errors={errors.levelDouble} message={t('VALIDATION_LEVEL_MIN')} />
              <ErrorMessage errorType="max" errors={errors.levelDouble} message={t('VALIDATION_LEVEL_MAX')} />
              <input type="number" min="1" max="9" id="levelDouble" name="levelDouble" ref={register({required: true, min: 1, max: 9})} placeholder={`${t('LEVEL_DOUBLE')}`} />
              <label htmlFor="levelDouble">{`${t('LEVEL_DOUBLE')} *`}</label>
            </div>
            <div className="field">
              <ErrorMessage errorType="min" errors={errors.levelPadel} message={t('VALIDATION_LEVEL_MIN')} />
              <ErrorMessage errorType="max" errors={errors.levelPadel} message={t('VALIDATION_LEVEL_MAX')} />
              <input type="number" min="1" max="9" id="levelPadel" name="levelPadel" ref={register({required: false, min: 1, max: 9})} placeholder={`${t('LEVEL_PADEL')}`} />
              <label htmlFor="levelPadel">{t('LEVEL_PADEL')}</label>
            </div>
            <div className="field">
              <input type="button" className={styles.add} onClick={() => append({value: `${clubs[0].id}`})} value={`${t('ADD_CLUB')}`} />
              <ErrorMessage errorType="validate" errors={errors.clubs} message={t('DUPLICATE_CLUBS')} />
              {renderClubs()}                
              <label>{t('CLUBS')}</label>
            </div>
            <input type="submit" value={`${t('SAVE')}`} />
          </form>
          </div>
          <div>
            <p dangerouslySetInnerHTML={{__html: t('CHANGE_PASSWORD_TEXT')}} />
            <PasswordChange />
          </div>
        </div>
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
};

export default EditCompetition;