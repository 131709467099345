import React, {Component} from 'react';
import {DbReducer} from '../../store/db/reducer';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import * as types from '../../store/db/types';
import Breadcrumb from '../../components/Breadcrumb';
import MatchesList from '../../components/Competition/MatchesList';
import Loading from 'components/Common/Loading';

import AdSense from '../../components/AdSense';
import {ThunkDispatch} from 'redux-thunk';
import * as actions from '../../store/db/actions';

interface DispatchProps {
  getMatchesForPlayers: (competitionId: number, players: types.Player[]) => void;
}
interface Props extends DispatchProps, WithTranslation {
  competition: types.Competition | null;
  matchesForUser: types.Match[];
  player: types.Player | null;
  userNotKnownAtCompetition: boolean;
}
interface State {
  filter: string | null;
  loading: boolean;
}

class Matches extends Component<Props, State> {

  state = {
    filter: null,
    loading: false
  };

  componentDidMount(): void {
    const {competition, matchesForUser, player, getMatchesForPlayers} = this.props;
    if (matchesForUser.length === 0 && player && competition) {
      this.setState({loading: true});
      getMatchesForPlayers(competition.id, [player]);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const {competition, matchesForUser, player, getMatchesForPlayers, userNotKnownAtCompetition} = this.props;
    if (player && player !== prevProps.player && competition) {
      this.setState({loading: true});
      getMatchesForPlayers(competition.id, [player]);
    }
    if (matchesForUser.length !== prevProps.matchesForUser.length
        || userNotKnownAtCompetition !== prevProps.userNotKnownAtCompetition) {
      this.setState({loading: false});
    }
  }

  render() {
    const { t, competition, userNotKnownAtCompetition } = this.props;
    if (!competition) {
      return t('NO_COMPETITION_SELECTED');
    }
    return (
        <div>
          <Breadcrumb competition={competition} title={'CONTESTANT_MATCHES_TITLE'} />
          {userNotKnownAtCompetition && <p>{t('USER_NOT_KNOWN_AT_COMPETITION')}</p>}
          {!userNotKnownAtCompetition && <p dangerouslySetInnerHTML={{__html: t('CONTESTANT_MATCHES_TEXT')}} />}
          {this.props.matchesForUser.length > 0 && this.renderMatches(competition)}
          {this.state.loading && <Loading />}
          <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        </div>
    );
  }

  renderMatches = (competition: types.Competition) => {
    const {matchesForUser} = this.props;

    return <MatchesList
        competition={competition}
        matches={matchesForUser}
        type="Player"
    />
  };
}

const mapStateToProps = (state: DbReducer) => state.db;

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => ({
  getMatchesForPlayers: (competitionId: number, players: types.Player[]) =>
      dispatch(actions.getMatchesForPlayers(competitionId, players))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Matches));