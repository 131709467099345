import * as types from './types';

export interface DbReducer {
  db: State
}

interface Action {
  type: string,
  payload: any
}

interface State {
  clubs: types.Club[];
  commonNews: types.News[];
  competition: types.Competition | null;
  groups: types.Group[];
  matches: types.Match[];
  matchesForUser: types.Match[];
  matchesPerDay: types.MatchesPerDay[];
  player: types.Player | null;
  userNotKnownAtCompetition: boolean;
}

const defaultState: State = {
  clubs: [],
  commonNews: [],
  competition: null,
  groups: [],
  matches: [],
  matchesForUser: [],
  matchesPerDay: [],
  player: null,
  userNotKnownAtCompetition: false
};

const reducer = (state = defaultState, action: Action) => {
  let currentMatches;
  switch (action.type) {
    case types.ADD_MATCHES:
      currentMatches = state.matches.slice();
      currentMatches.push(...action.payload);
      return { ...state, matches: currentMatches };
    case types.ADD_MATCHES_PER_DAY:
      currentMatches = state.matchesPerDay.slice();
      currentMatches.push(action.payload);
      return { ...state, matchesPerDay: currentMatches };
    case types.CLEAR_MATCHES:
      return { ...state, matches: [], matchesPerDay: [], matchesForUser: [], registrationsInfo: [], userNotKnownAtCompetition: false };
    case types.GET_CLUBS:
      return { ...state, clubs: action.payload };
    case types.GET_COMMON_NEWS:
      return { ...state, commonNews: action.payload };
    case types.GET_COMPETITION:
      return { ...state, competition: action.payload };
    case types.GET_GROUPS:
      return { ...state, groups: action.payload };
    case types.STORE_PLAYER:
      return { ...state, player: action.payload };
    case types.GET_USER_MATCHES:
      return { ...state, matchesForUser: action.payload, userNotKnownAtCompetition: action.payload.length === 0 };
    case types.SET_CLUB_ID:
      return { ...state, clubId: action.payload };
    case types.UPDATE_MATCH:
      return updateMatch(action.payload, state);
    default:
      return state;
  }
};

const updateMatch = (updatedMatch: types.Match, state: State) => {
  // Update matches
  let matches = state.matches.slice();
  matches = matches.map((item) => {
    if (item.id !== updatedMatch.id) {
      return item;
    }
    return {
      ...item,
      match: {
        ...updatedMatch
      }
    };
  });

  // Update matches for user
  let matchesForUser = state.matchesForUser.slice();
  matchesForUser = matchesForUser.map((item) => {
    if (item.id !== updatedMatch.id) {
      return item;
    }
    return {
      ...item,
      match: {
        ...updatedMatch
      }
    };
  });

  return { ...state, matches, matchesForUser, matchesPerDay: [] };
};

export default reducer;