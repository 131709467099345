import React, {Component} from 'react';
import {connect} from 'react-redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import ReactGA from 'react-ga';

import Breadcrumb from '../../components/Breadcrumb';
import {DbReducer} from '../../store/db/reducer';

import * as ROUTES from 'constants/routes';
import * as dbTypes from '../../components/Firebase/types';
import * as types from '../../store/db/types';
import AdSense from '../../components/AdSense';
import * as api from '../../api';

interface Props extends WithTranslation{
  competition: types.Competition | null;
}
interface State {
  regulations: string | null
}

class Regulations extends Component<Props, State> {

  state = {
    regulations: null
  };

  componentDidMount(): void {
    ReactGA.pageview(ROUTES.REGULATIONS);
    const path = window.location.pathname.split('/');
    const competitionId = (path.length === 3) ? path[2] : localStorage.getItem('competitionId');
    if (competitionId) {
      api.get(`competitions/${competitionId}/regulations`).then((regulations: dbTypes.Regulations) => {
        this.setState({regulations: regulations.regulations});
      });

    }
  }

  render() {
    const { t, competition } = this.props;
    const { regulations } = this.state;
    if (!regulations) {
      return null;
    }
    if (!competition) {
      return t('NO_COMPETITION_SELECTED');
    }
    return (
        <div>
          <Breadcrumb competition={competition} title="REGULATIONS_TITLE"/>
          <p dangerouslySetInnerHTML={{__html: regulations!}} />
          <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        </div>
    );
  }
}

const mapStateToProps = (state: DbReducer) => state.db;

export default withTranslation()(connect(mapStateToProps, null)(Regulations));