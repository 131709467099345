import React, {Fragment, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import * as types from '../../store/db/types';

import styles from './EditResult.module.scss';

interface Props {
  competition: types.Competition;
  match: types.Match;
  type: 'Manager' | 'Player' | 'Unknown';
  onSave(result: types.Match): void;
  onClose(): void;
}

const EditVaryingResult = ({competition, match, type, onSave, onClose}: Props) => {

  const {t} = useTranslation();

  const [result, setResult] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>();
  const [reserve1Played, setReserve1Played] = useState<boolean>(false)
  const [reserve2Played, setReserve2Played] = useState<boolean>(false)
  const [reserve3Played, setReserve3Played] = useState<boolean>(false)
  const [reserve4Played, setReserve4Played] = useState<boolean>(false)

  useEffect(() => {
    setResult(match.result || '');
    setReserve1Played(match.registrations.filter(registration => registration.home_away === 'H')[0].reserve_played);
    setReserve2Played(match.registrations.filter(registration => registration.home_away === 'H')[1].reserve_played);
    setReserve3Played(match.registrations.filter(registration => registration.home_away === 'A')[0].reserve_played);
    setReserve4Played(match.registrations.filter(registration => registration.home_away === 'A')[1].reserve_played);
  }, [competition.id, match.group_id, match.registrations, match.result]);

  const onSaveResult = () => {

    // Set result and reserves played to match
    if (result !== undefined) {
      match.result = result;
      match.registrations.filter(registration => registration.home_away === 'H')[0].reserve_played = reserve1Played;
      match.registrations.filter(registration => registration.home_away === 'H')[1].reserve_played = reserve2Played;
      match.registrations.filter(registration => registration.home_away === 'A')[0].reserve_played = reserve3Played;
      match.registrations.filter(registration => registration.home_away === 'A')[1].reserve_played = reserve4Played;
    }


    if (result !== undefined && type === 'Manager') {
      onSave(match);
    } else {
      if (!result) {
        setErrMsg(t('ERROR_REQUIRED'));
      } else if (!isResultCorrect(result)) {
        setErrMsg(t('ERROR_NOT_VALID'));
      } else {
        onSave(match);
      }
    }
  };

  const isResultCorrect = (currentResult: string) => {
    if (match.count_type === types.CountType.BEST_OF_THREE.valueOf()) {
      return currentResult.match('[01234567]-[01234567] [01234567]-[01234567]')
          || currentResult.match('[01234567]-[01234567] [01234567]-[01234567] [01234567]-[01234567]');
    } else if (match.count_type === types.CountType.TWO_FULL_SETS.valueOf()) {
      return currentResult.match('[01234567]-[01234567] [01234567]-[01234567]');
    } else if (match.count_type === types.CountType.TWO_SETS_EQUALS_ON_6.valueOf()) {
      return currentResult.match('[01234567]-[01234567] [01234567]-[01234567]');
    } else if (match.count_type === types.CountType.COUNT_GAMES.valueOf()) {
      return currentResult.match('\\d-\\d');
    }
    return false;
  }

  const getPlayer = (homeAway: 'H' | 'A', idx: number, currentReservePlayer: boolean): string => {
    if (currentReservePlayer) {
      return t('RESERVE_PLAYED');
    }
    const mt = match.registrations.filter(registration => registration.home_away === homeAway)[idx];
    return mt.registration_name;
  }

  const setReservePlayed = (currentReservePlayed: boolean, setCurrentReservePlayed: any) => {
    currentReservePlayed = !currentReservePlayed;
    setCurrentReservePlayed(currentReservePlayed);
  }

  const renderRegistrations = () => {
    return (
        <Fragment>
          <div className="info"><i className="fa fa-info-circle" />{t('RESERVE_PLAYED_INFO')}</div>
          <div className={styles.registrations}>
            <div>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={!reserve1Played}
                    onChange={() => setReservePlayed(reserve1Played, setReserve1Played)}
                />
                <span className={styles.slider} />
              </label>
              <span className={styles.player}>{getPlayer('H', 0, reserve1Played)}</span>
            </div>
            <div />
            <div>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={!reserve3Played}
                    onChange={() => setReservePlayed(reserve3Played, setReserve3Played)}
                />
                <span className={styles.slider} />
              </label>
              <span className={styles.player}>{getPlayer('A', 0, reserve3Played)}</span>
            </div>
            <div />
            <div>-</div>
            <div />
            <div>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={!reserve2Played}
                    onChange={() => setReservePlayed(reserve2Played, setReserve2Played)}
                />
                <span className={styles.slider} />
              </label>
              <span className={styles.player}>{getPlayer('H', 1, reserve2Played)}</span>
            </div>
            <div />
            <div>
              <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={!reserve4Played}
                    onChange={() => setReservePlayed(reserve4Played, setReserve4Played)}
                />
                <span className={styles.slider} />
              </label>
              <span className={styles.player}>{getPlayer('A', 1, reserve4Played)}</span>
            </div>
          </div>
        </Fragment>
    );
  }

  return (
      <div className="popup">
        <div className="header">{t('EDIT_RESULT_TITLE')}</div>
        <div className="content">
          <p>{t('EDIT_RESULT_TEXT')}</p>
          {renderRegistrations()}
          <label style={{color: 'red'}}>{errMsg}</label>
          <input
              type="text"
              value={result}
              pattern="[0123456]-[0123456] [0123456]-[0123456]"
              placeholder={t('RESULT')}
              required={type !== 'Manager'}
              onChange={(event) => setResult(event.target.value)}
          />
          <input
              type="button"
              value={'' + t('SAVE')}
              className="button"
              onClick={onSaveResult}
          />
          <input
              type="button" value={'' + t('CANCEL')}
              className="lastButton second"
              onClick={onClose}
          />
        </div>
      </div>
  );
}

export default EditVaryingResult;