import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import * as types from 'store/db/types';
import * as utils from 'utils';

interface Props {
  competition: types.Competition;
  registrations: types.Registration[];
  onClick(registration: types.Registration): void;
}

const RegistrationsList = ({competition, registrations, onClick}: Props) => {
  const {t} = useTranslation();

  const renderRegistrations = () => {
    return (
      <div className="table">
        <table>
          <tbody>
            <tr>
              <th>{t('REGISTRATION')}</th>
              <th />
            </tr>
            {registrations
                .map((registration: types.Registration, idx: number) => {
                  return (<Fragment key={idx}>
                    <tr>
                      <td>{utils.getName(t, registration)}</td>
                      <td>
                        <i className="far fa-envelope" style={{float: 'right'}}
                          onClick={() => onClick(registration)} />
                      </td>
                    </tr>
                  </Fragment>)
                })}
          </tbody>
        </table>
      </div>
    )
  };

  return (
      <div>
        {renderRegistrations()}
      </div>
  );
}

export default RegistrationsList;