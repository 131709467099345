import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Registration} from 'store/db/types';
import * as api from 'api';

import styles from './RegistrationInfo.module.scss';

interface Props {
  registrationIds: number[];
  onClose(): void;
}

const RegistrationInfo = (props: Props) => {

  const [t] = useTranslation();
  const [registration, setRegistration] = useState<Registration>();

  useEffect(() => {
    (async () => {
        if (props.registrationIds.length > 0) {
          const useReg = await api.get(`registrations/${props.registrationIds[0]}`);
  
          for (let i = 1; i < props.registrationIds.length; i++) {
            const currentRegistration = await api.get(`registrations/${props.registrationIds[i]}`);
            useReg?.players?.push(...currentRegistration.players);
          }
          setRegistration(useReg);
        }
    })();
  }, [props.registrationIds])


  return (
      <div className="popup">
        <div className="header">{t('REGISTRATION_INFO_TITLE')}</div>
        <div className="content">
          {registration && registration.players.map((item, idx) => (
              <p key={idx}>
                <strong>{item.name}</strong><br />
                {item.mobile && <><i className={cn('fa','fa-mobile',styles.icon, styles.mobile)} />{item.mobile}<br /></>}
                {item.phone && <><i className={cn('fa','fa-phone-square',styles.icon)} />{item.phone}<br /></>}
                {item.email && <><i className={cn('fa','fa-envelope',styles.icon)} />{item.email}<br /></>}
              </p>
          ))}
          <input
              type="button" value={'' + t('CLOSE')}
              className="lastButton"
              onClick={props.onClose}
          />
        </div>
      </div>
  );
}

export default RegistrationInfo;