import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

import ErrorMessagePattern from "../Validation/ErrorMessagePattern";

interface Props {
  register: any;
  errors: any;
  newLayout?: boolean;
}

const PhoneNumber = ({register, errors, newLayout}: Props) => {

  const [t] = useTranslation();

  return (
    <Fragment>
      {newLayout && <ErrorMessagePattern errors={errors} errorMsgKey={'VALIDATION_FIELD_PHONE_NUMBER_PATTERN'} />}
      <input
        name="phone"
        id="phone"
        ref={register({
          pattern: /(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/i,
        })}
        type="tel"
        placeholder={t("PHONE_NUMBER")}
      />
      {newLayout && <label htmlFor="phone">{`${t('PHONE_NUMBER')}`}</label>}
      {!newLayout && <ErrorMessagePattern errors={errors} errorMsgKey={'VALIDATION_FIELD_PHONE_NUMBER_PATTERN'} />}
    </Fragment>
  )
}

export default PhoneNumber;