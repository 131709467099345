import ErrorMessageRequired from "components/Validation/ErrorMessageRequired";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import * as types from "../../store/db/types";

interface Props {
  register: any;
  errors: any;
  newLayout?: boolean;
}

const Gender = ({register, errors, newLayout}: Props) => {

  const [t] = useTranslation();

  return (
    <Fragment>
      {newLayout && <ErrorMessageRequired errors={errors} />}
      <select
        name="gender"
        placeholder={t('GENDER')}
        ref={register({required: true})}
      >
        <option value={types.Gender.MALE}>{t('MALE')}</option>
        <option value={types.Gender.FEMALE}>{t('FEMALE')}</option>
      </select>
      {!newLayout && <ErrorMessageRequired errors={errors} />}      
    </Fragment>
  )
}

export default Gender;