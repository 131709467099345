import React, {CSSProperties} from 'react';

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

interface Props {
  className?: string;
  style?: CSSProperties;
  client: string;
  slot: string;
  layout?: string;
  layoutKey?: string;
  format?: string;
  responsive?: string;
}

export default class Google extends React.Component<Props> {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<any>, nextContext: any): boolean {
    return false;
  }

  render() {
    return (
        <>
          <hr className="adsense" />
          <ins className={`${this.props.className || ''} adsbygoogle`}
               style={this.props.style || {display: 'block'}}
               data-ad-client={this.props.client}
               data-ad-slot={this.props.slot}
               data-ad-layout={this.props.layout || ''}
               data-ad-layout-key={this.props.layoutKey || ''}
               data-ad-format={this.props.format || 'auto'}
               data-full-width-responsive={this.props.responsive || 'false'} />
        </>
    );
  }
}