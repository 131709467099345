import React, { Fragment } from 'react';

interface Props {
  errors: any;
  errorType: string;
  message?: string;
}

const ErrorMessage = ({errors, errorType, message}: Props) => {
  return (
    <Fragment>
      {errors && hasErrorType(errors, errorType) && (<p className="error">{message ? message : errors.message}</p>)}
    </Fragment>
  );
}

const hasErrorType = (errors: any, errorType: string): boolean => {
  if (Array.isArray(errors)) {
    for (const error of errors) {
      if (error?.value.type === errorType) {
        return true;
      }
    }
  }
  return errors.type === errorType;
}

export default ErrorMessage;