import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import ReactGA from 'react-ga';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-day-picker/lib/style.css';

import './i18n';
import App from './containers/App';
import rootReducer from './store';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactGA.initialize('G-5XVWKK1E3S', { gaOptions: { siteSpeedSampleRate: 100 }});

ReactDOM.render(
    <Provider store={store}>
        <App  />
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
