import React, {useEffect, useState} from 'react';
import cn from 'classnames';

export enum Action {
  EDIT, DELETE
}

export interface Icon {
  action: Action;
  disabled?: boolean;
  tooltip?: string;
}

export interface Row {
  item: any;
  columns: (string | number)[];
  icons: Icon[];
}

interface Props {
  header: string[];
  rows: Row[];
  isRowSelectable?: boolean;
  onClick?(action: Action, item: any): void;
  onChangeSelectedRow?(row?: Row): void;
}

const Table = ({header, rows, isRowSelectable, onClick, onChangeSelectedRow}: Props) => {

  const [selectedRowIdx, setSelectedRowIdx] = useState<number>();
  const [currentRows, setCurrentRows] = useState<Row[]>([]);

  useEffect(() => {
    if (currentRows.length !== rows.length || (selectedRowIdx !== undefined && selectedRowIdx > rows.length - 1)) {
      setCurrentRows(rows);
      setSelectedRowIdx(undefined);
      onChangeSelectedRow && onChangeSelectedRow(undefined);
    }
  }, [selectedRowIdx, currentRows, rows, onChangeSelectedRow]);

  const renderHeader = () => {
    if (header.length === 0) return null;

    return (
      <tr>
        {header.map((col, idx) => <th key={idx}>{col}</th>)}
        <th />
      </tr>
    );
  };

  const getIconClass = (icon: Icon) => {
    if (icon.action === Action.DELETE) return `far fa-trash-alt tooltip ${icon.disabled ? 'disabled' : ''}`;
    if (icon.action === Action.EDIT) return `fa fa-pencil-alt tooltip ${icon.disabled ? 'disabled' : ''}`;
    return undefined;
  }

  const selectRowIdx = (row: Row, idx: number) => {
    if (isRowSelectable) {
      if (idx === selectedRowIdx) {
        setSelectedRowIdx(undefined);
        onChangeSelectedRow && onChangeSelectedRow(undefined);
      } else {
        setSelectedRowIdx(idx);
        onChangeSelectedRow && onChangeSelectedRow(row);
      }
    }
  }
  
  return (
    <div className="table">
      <table>
        <tbody>
          {renderHeader()}
          {rows.map((row, idx) =>(
                  <tr key={idx}
                      onClick={() => selectRowIdx(row, idx)}
                      className={idx === selectedRowIdx ? cn('selectable', 'selected') : isRowSelectable ? 'selectable' : undefined}>
                    {row.columns.map((col, idx) => <td key={idx}>{col}</td>)}
                    <td>
                      {row.icons.reverse().map((icon, idx) => (
                        <i
                          key={idx}
                          className={getIconClass(icon)}
                          style={{float: 'right'}}
                          onClick={() => !icon.disabled && onClick && onClick(icon.action, row.item)}
                        >
                          {icon.tooltip && <div>{icon.tooltip}</div>}
                        </i>
                      ))}
                    </td>
                  </tr>)
              )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;