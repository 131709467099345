import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import ErrorMessageMinMaxLength from "../Validation/ErrorMessageMinMaxLength";

interface Props {
  register: any;
  errors: any;
  newLayout?: boolean;
}

const PlayerNumber = ({register, errors, newLayout}: Props) => {

  const [t] = useTranslation();

  return (
    <Fragment>
      {newLayout && <ErrorMessageMinMaxLength errors={errors} errorMsgKey={'VALIDATION_FIELD_PLAYER_NUMBER_PATTERN'}/>}
      <input
        name="playerNumber"
        id="playerNumber"
        ref={register({ min: 10000000, max: 99999999 })}
        type="number"
        placeholder={t("PLAYER_NUMBER")}
      />
      {newLayout && <label htmlFor="playerNumber">{`${t('PLAYER_NUMBER')}`}</label>}
      {!newLayout && <ErrorMessageMinMaxLength errors={errors} errorMsgKey={'VALIDATION_FIELD_PLAYER_NUMBER_PATTERN'}/>}
    </Fragment>
  )
}

export default PlayerNumber;