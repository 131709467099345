import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  errors: any;
}

const ErrorMessageRequired = ({errors}: Props) => {
  const [t] = useTranslation();

  return (
    <Fragment>
      {errors && errors.type === "required" && <p className="error">{t('VALIDATION_FIELD_MANDATORY')}</p>}
    </Fragment>)
}

export default ErrorMessageRequired;
