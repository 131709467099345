import * as types from '../../store/db/types';

export interface RegistrationResult {
  registrationId: number;
  registration: string;
  played: number;
  points: number;
  matchesFor: number;
  matchesAgainst: number;
  setsFor: number;
  setsAgainst: number;
  gamesFor: number;
  gamesAgainst: number;
}

export function getTable(groupMatches: types.Match[]): RegistrationResult[] {
  // Filter registrations
  const registrationResults: RegistrationResult[] = [];
  groupMatches.forEach(match => {
    match.registrations.forEach(registration => {
      if (registrationResults.filter(tr => tr.registrationId === registration.registration_id).length === 0) {
        registrationResults.push({registrationId: registration.registration_id, registration: registration.registration_name,
          played: 0, points: 0, matchesFor: 0, matchesAgainst: 0, setsFor: 0, setsAgainst: 0, gamesFor: 0, gamesAgainst: 0});
      }
    });
  });

  // Define registration results
  groupMatches.forEach(match => {
    const matchResult = match.result;
    if (matchResult) {
      match.registrations.forEach(registration => {
        const home = registrationResults.find(result => registration.home_away === 'H' && result.registrationId === registration.registration_id);
        if (home && !registration.reserve_played) processMatch(matchResult, home, true);
        const away = registrationResults.find(result => registration.home_away === 'A' && result.registrationId === registration.registration_id);
        if (away && !registration.reserve_played) processMatch(matchResult, away, false);
      });
    }
  });

  return registrationResults.sort(comparator);
}

const processMatch = (matchResult: string, result: RegistrationResult, isHome: boolean) => {
  result.played += 1;

  let setFor = 0;
  let setAgainst = 0;
  let setEqual = 0;
  let gamesFor = 0;
  let gamesAgainst = 0;

  // Process sets
  const sets = matchResult.trim().split(' ');
  for (const set of sets) {
    const setResult = processSet(set, result, isHome);
    setFor += setResult.setFor;
    setAgainst += setResult.setAgainst;
    setEqual += setResult.setEquals;
    gamesFor += setResult.gamesFor;
    gamesAgainst += setResult.gamesAgainst;
  }

  // Process match
  if (setFor > setAgainst) {
    result.matchesFor++;
  }
  else if (setAgainst > setFor) {
    result.matchesAgainst++;
  }
  result.points += (setFor * 2) + setEqual;
  result.gamesFor += gamesFor;
  result.gamesAgainst += gamesAgainst;
};

interface SetResult {
  setFor: number;
  setAgainst: number;
  setEquals: number;
  gamesFor: number;
  gamesAgainst: number;
}

const processSet = (set: string, result: RegistrationResult, isHome: boolean): SetResult => {
  const setResult: SetResult = {setFor: 0, setAgainst: 0, setEquals: 0, gamesFor: 0, gamesAgainst: 0};

  const games = set.split('-');
  const idx1 = isHome ? 0 : 1;
  const idx2 = isHome ? 1 : 0;
  setResult.gamesFor += +games[idx1];
  setResult.gamesAgainst += +games[idx2];
  if (+games[idx1] > +games[idx2]) {
    setResult.setFor++;
    result.setsFor++;
  } else if (+games[idx1] < +games[idx2]) {
    setResult.setAgainst++;
    result.setsAgainst++;
  } else {
    setResult.setEquals++;
  }

  return setResult;
};

const comparator = (a: RegistrationResult, b: RegistrationResult): number => {
  if (a.points > b.points) {
    return -1;
  }
  else if (a.points < b.points) {
    return 1;
  }
  else if (a.played > b.played) {
    return 1;
  }
  else if (a.played < b.played) {
    return -1;
  }
  else if (a.gamesFor - a.gamesAgainst > b.gamesFor - b.gamesAgainst) {
    return -1;
  }
  else if (a.gamesFor - a.gamesAgainst < b.gamesFor - b.gamesAgainst) {
    return 1;
  }
  else if (a.gamesFor > b.gamesFor) {
    return -1;
  }
  else if (a.gamesFor < b.gamesFor) {
    return 1;
  }
  return 0;
};